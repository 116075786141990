import { HandlerFn } from 'store/types';
import { EursStakingActionTypes } from 'store/eursStaking/actionTypes';
import { EursStakingState } from 'store/eursStaking/types';
import { eursStakingSetStateAction, eursStakingSetStatusAction } from 'store/eursStaking/actions';

type EursStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<EursStakingState, ReturnType<F>>;

const setState: EursStakingActionFn<typeof eursStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: EursStakingActionFn<typeof eursStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const EURS_STAKING_HANDLERS = {
  [EursStakingActionTypes.SET_STATE]: setState,
  [EursStakingActionTypes.SET_STATUS]: setStatus,
};
