/* eslint-disable no-console */
import {
  referralsMeGetReferralCodeAction,
  referralsMeSetStateAction,
  referralsMeSetStatusAction,
} from 'store/referrals/me/actions';
import { RequestStatus, setNotification } from '@workstream/shared';
import { put, takeLeading } from 'redux-saga/effects';
import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import { api } from 'utils';
import { selectAddress } from 'store/utils';
import { AxiosResponse } from 'axios';

type Result = {
  referralCode: string,
};

function* referralsMeGetReferralCodeSaga(
  { type }: ReturnType<typeof referralsMeGetReferralCodeAction>,
) {
  try {
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();

    const result: AxiosResponse<Result> = yield api.get(`/users/user/code?address=${address}`);

    yield put(referralsMeSetStateAction({ referralCode: result.data.referralCode }));
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    if (!e.response || (e.response && e.response.status !== 404)) {
      setNotification({ type: 'error', message: e.message });
      console.log('error', e.message);
      console.log('error location getReferralCodeSaga');
    }
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getReferralCodeSaga() {
  yield takeLeading(
    ReferralsMeActionTypes.GET_REFERRAL_CODE,
    referralsMeGetReferralCodeSaga,
  );
}
