/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable require-yield */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  put, takeEvery, call, all,
} from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { api } from 'utils';
import { AxiosResponse } from 'axios';
import {
  lockupInfoGetBlockInfoAction,
  lockupInfoSetStateAction,
  lockupInfoSetStatusAction,
} from 'store/lockupInfo/actions';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { GetXbeLockupResult } from 'store/lockupInfo/types';
// import { selectAddress } from 'store/utils';
import {
  LiquidityPool, POOL_ADDRESSES,
  Token, TOKEN_ADDRESSES, TOKEN_DECIMALS,
} from 'appConstants';
import { getTokenDecimalByAddress } from 'utils/token';
import { Contract } from 'web3-eth-contract';
import {
  pairEthXbeAbi, sushiVaultAbi, treasuryAbi, wethAbi,
} from 'assets/abi';
import { ListRewardsContractsAddresses } from 'store/stakeCurveLp/types';

function* getPriceByPool(pool: LiquidityPool, mainToken: Token) {
  // eslint-disable-next-line no-console
  if (POOL_ADDRESSES[pool]) {
    const pairContract: Contract = yield getContract(
      pairEthXbeAbi,
      POOL_ADDRESSES[pool],
    );

    pairContract.options.address = `${POOL_ADDRESSES[pool]}`;

    const token0Address: string = yield pairContract.methods.token0().call();
    const token1Address: string = yield pairContract.methods.token1().call();

    const mainTokenAddress = TOKEN_ADDRESSES[mainToken];
    const isToken0Main = token0Address === mainTokenAddress;

    const {
      0: token0,
      1: token1,
    } = yield pairContract.methods.getReserves().call();

    const token0BN = new BigNumber(
      yield web3.utils.fromWei(
        token0,
        isToken0Main ? TOKEN_DECIMALS[mainToken] : getTokenDecimalByAddress(token0Address),
      ),
    );

    const token1BN = new BigNumber(
      yield web3.utils.fromWei(
        token1,
        isToken0Main ? getTokenDecimalByAddress(token1Address) : TOKEN_DECIMALS[mainToken],
      ),
    );

    const priceBN = isToken0Main
      ? token1BN.dividedBy(token0BN)
      : token0BN.dividedBy(token1BN);

    const priceReverseBN = isToken0Main
      ? token0BN.dividedBy(token1BN)
      : token1BN.dividedBy(token0BN);

    return {
      price: priceBN.isGreaterThan(0) ? priceBN.toString() : '0',
      reversePrice: priceReverseBN.isGreaterThan(0) ? priceReverseBN.toString() : '0',
    };
  }
  return {
    price: '0',
    reversePrice: '0',
  };
}

function* lockupInfoGetBlockInfoSaga(
  { type }: ReturnType<typeof lockupInfoGetBlockInfoAction>,
) {
  try {
    yield put(lockupInfoSetStatusAction({ type, status: RequestStatus.REQUEST }));
    // const address: string = yield selectAddress();

    // TODO: To ask about the address used when querying this api.
    const { data }: AxiosResponse<GetXbeLockupResult> = yield api.get(
      '/statistics/xbe/lockup?address=0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16',
    );

    const treasury: Contract = yield getContract(
      treasuryAbi,
      '0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16',
    );

    const totalStakeContract: Contract = yield getContract(
      wethAbi,
      '0x0b1fA4b11Edbcb6d35731549211D83C857fFBC0a',
    );

    // const sushiVaultContract: Contract = yield getContract(
    //   sushiVaultAbi,
    //   '0x5220871eB8Fab14400787aA27FC26774CBC46F99',
    // );

    const treasuryBalance: string = yield treasury.methods.balanceOf('0x0acfe909ed42452768686ccb41b2190598c7a544').call();

    const xbeContractBalance: string = yield treasury.methods.balanceOf('0x0b1fa4b11edbcb6d35731549211d83c857ffbc0a').call();

    const totalStake: string = yield totalStakeContract.methods.totalSupply().call();

    // const { getRewardTokensCount, getRewardToken } = sushiVaultContract.methods;

    // const tokensCount: string = yield getRewardTokensCount().call();

    // const rewardsContractsPromise: string[] = [];
    // for (let i = 0; i < Number(tokensCount); i += 1) {
    //   rewardsContractsPromise.push(getRewardToken(i).call());
    // }
    // const rewardTokensAddresses: string[] = yield all(rewardsContractsPromise);

    // let amountRewardRates = new BigNumber('0');

    // for (let i = 0; i < rewardTokensAddresses.length; i += 1) {
    //   const rewardTokenPrice: string = yield sushiVaultContract.methods.rewardPerToken(rewardTokensAddresses[i]).call();
    //   const rewardRates: string = yield sushiVaultContract.methods.rewardRates(rewardTokensAddresses[i]).call();

    //   const value = Number(rewardTokenPrice) * Number(rewardRates);

    //   const rewardTokenPriceFromWei = Number(
    //     web3.utils.fromWei(rewardTokenPrice.toString()),
    //   ).toFixed(2);

    //   const rewardRatesFromWei = Number(
    //     web3.utils.fromWei(rewardRates.toString()),
    //   ).toFixed(2);

    //   console.log('rewardTokenPrice', rewardTokenPriceFromWei);
    //   console.log('rewardRates', rewardRatesFromWei);

    //   const rewardRateCost = new BigNumber(rewardTokenPriceFromWei).multipliedBy(rewardRatesFromWei);
    //   amountRewardRates = amountRewardRates.plus(rewardRateCost);
    // eslint-disable-next-line max-len
    // const rewardTokenPriceInUSD = getTokenPriceInUSD(rewardTokenAddress);
    // const rewardRatesCostInUsd = rewardRates * rewardTokenPriceInUSD;
    // amountRewardRatesInUSD = amountRewardRatesInUSD + rewardRatesCostInUsd;
    // }

    // const value = Number(
    //   web3.utils.fromWei(amountRewardRates.toString()),
    // ).toFixed(2);

    // console.log('in wei', amountRewardRates.toFixed());
    // // console.log('from wei', value);

    const result: Record<LiquidityPool, { price: string, reversePrice: string }> = {
      ETH_USDT: yield call(getPriceByPool, LiquidityPool.ETH_USDT, Token.USDT),
      ETH_XBE: yield call(getPriceByPool, LiquidityPool.ETH_XBE, Token.ETH),
      XBE_USDT: yield call(getPriceByPool, LiquidityPool.XBE_USDT, Token.USDT),
    };

    yield put(lockupInfoSetStateAction({
      dailyApy: new BigNumber(data.daily_apy).toFixed(2),
      apyPercentage: new BigNumber(data.apy).toFixed(2),
      currentCirculatingSupply: data.circulating_supply.toString(),
      totalLockedXBE: data.total_locked_xbe.toString(),
      percentageTotalLockedXBE: new BigNumber(data.total_locked_xbe_share).toFixed(2),
      maxSupply: data.total_circulating_supply.toString(),
      totalStake: Number(
        web3.utils.fromWei(totalStake),
      ).toFixed(2),
      treasury: Number(
        web3.utils.fromWei(treasuryBalance),
      ).toFixed(2),
      xbeContractBalance: Number(
        web3.utils.fromWei(xbeContractBalance),
      ).toFixed(2),
      price: {
        ETH_USDT: result.ETH_USDT.price,
        ETH_XBE: result.ETH_XBE.price,
        XBE_USDT: result.XBE_USDT.price,
      },
      reversePrice: {
        ETH_USDT: result.ETH_USDT.reversePrice,
        ETH_XBE: result.ETH_XBE.reversePrice,
        XBE_USDT: result.XBE_USDT.reversePrice,
      },
    }));

    yield put(lockupInfoSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getBlockInfoSaga');
    yield put(lockupInfoSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    LockupInfoActionTypes.GET_BLOCK_INFO,
    lockupInfoGetBlockInfoSaga,
  );
}
