import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { IronBankStakingActionTypes } from 'store/ironBankStaking/actionTypes';
import { ResponsePoolInfo } from 'store/ironBankStaking/types';
import {
  ironBankStakingSetStateAction,
  ironBankStakingSetStatusAction,
  ironBankStakingGetBlockInfoAction,
} from 'store/ironBankStaking/actions';
import {
  RequestStatus, setNotification,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';

function* ironBankStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof ironBankStakingGetBlockInfoAction>,
) {
  try {
    yield put(ironBankStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=ironbankVault`);

    yield put(ironBankStakingSetStateAction({
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
    }));
    yield put(ironBankStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(ironBankStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    IronBankStakingActionTypes.GET_BLOCK_INFO,
    ironBankStakingGetBlockInfoSaga,
  );
}
