/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
// import axios from 'axios';
import { LocationActionTypes } from 'store/location/actionTypes';
import {
  getLocationAction,
  locationSetStatusAction,
  locationSetStateAction,
} from 'store/location/actions';
// import { xbfApi } from 'utils';
import {
  RequestStatus,
  setNotification,
} from '@workstream/shared';
// import { AxiosResponse } from 'axios';
// import axios from 'axios';

function* locationGetLocationSaga({
  type,
}: ReturnType<typeof getLocationAction>) {
  try {
    yield put(locationSetStatusAction({ type, status: RequestStatus.REQUEST }));

    // // @ts-ignore
    // yield fetch(
    //   'http://164.92.253.185:4000/wallet/0x0f65c7d2faac5b30d00840b775663792588528e1',
    // )
    //   .then((res) => res.json())
    //   // eslint-disable-next-line no-console
    //   .then((data) => console.log(data))
    //   .catch((err) => console.log(err));

    // // const data1: AxiosResponse<any> = yield xbfApi.get(
    // //   '/wallet/0x0f65c7d2faac5b30d00840b775663792588528e1',
    // // );

    // // console.log(data1);

    // @ts-ignore
    let result;
    yield fetch('https://lucky-moon-d435.api1836.workers.dev/')
      .then((response) => response.json())
      .then((data) => {
        result = data;
      })
      .catch((err) => console.log(err));

    yield put(
      locationSetStateAction({
        // @ts-ignore
        country: result?.countryCode ? result.countryCode : '',
      }),
    );

    yield put(locationSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getLocation');
    yield put(locationSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getLocationSaga() {
  yield takeEvery(
    LocationActionTypes.GET_LOCATION,
    locationGetLocationSaga,
  );
}
