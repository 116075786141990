import React, {
  ChangeEvent, FC, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Dropdown, formattersDate, MetamaskStatus, PlatformHeaderBlock, Preloader,
  RequestStatus, Text, Tooltip, web3, WriteContractStatus,
} from '@workstream/shared';
import { BlockWithList, InputWithMaxButton } from 'components';
import { Option } from 'react-dropdown';
import { xbeLockupDepositAction, xbeLockupGetBlockInfoAction } from 'store/xbeLockup/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  meSelector, useShallowSelector, web3Selector, xbeLockupSelector,
} from 'store/selectors';
import { generateArrMonthsWithDates } from 'utils';
import { XbeLockupActionTypes } from 'store/xbeLockup/actionTypes';
import BigNumber from 'bignumber.js';
import { RequirementWalletProvider } from 'containers';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const options = generateArrMonthsWithDates(new Date());

const XBELockupForm: FC<Props> = ({
  className,
}) => {
  const metamaskStatus = useSelector(web3Selector.getProp('metamaskStatus'));
  const getBlockXbeLockupInfo =
    useSelector(xbeLockupSelector.getStatus(XbeLockupActionTypes.GET_BLOCK_INFO));
  const {
    isLockedXBE,
    balances: {
      votingStakingRewards: votingStakingRewardsBalance,
    },
  } = useSelector(meSelector.getState);
  const {
    meLockedAssets, percentOfTotalStaked, submitStatus,
  } = useShallowSelector(xbeLockupSelector.getState);
  const getBlockInfoStatus =
    useSelector(xbeLockupSelector.getStatus(XbeLockupActionTypes.GET_BLOCK_INFO));

  const dispatch = useDispatch();

  useEffect(() => {
    if (metamaskStatus === MetamaskStatus.ADDRESS_SELECTED) {
      dispatch(xbeLockupGetBlockInfoAction());
    }
  }, [dispatch, metamaskStatus]);

  const list = [
    {
      label: 'Your Locked Assets',
      value: web3.utils.fromWei(meLockedAssets),
    },
    {
      label: 'Your % of Total Locked',
      value: `${(Number(percentOfTotalStaked) * 100).toFixed(2)}%`,
    },
  ];

  const [month, setMonth] = useState<Option | undefined>(options[0]);

  useEffect(() => {
    if (getBlockInfoStatus === RequestStatus.SUCCESS) {
      setMonth(options[options.length - 1]);
    }
  }, [getBlockInfoStatus]);

  const [value, setValue] = useState('');

  const handlerInput = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const onMaxClick = () => {
    setValue(isLockedXBE ? '0' : votingStakingRewardsBalance);
  };

  const onDepositClick = () => {
    if (month !== undefined) dispatch(xbeLockupDepositAction({ value, lockTime: month.value }));
  };

  const isDepositButtonDisabled = useMemo(() => {
    if (
      submitStatus === WriteContractStatus.APPROVING ||
      submitStatus === WriteContractStatus.WRITING ||
      Number.isNaN(value) ||
      Number(value) <= 0 ||
      isLockedXBE
    ) return true;

    const valueBN = new BigNumber(value);

    return valueBN.isGreaterThan(votingStakingRewardsBalance);
  }, [submitStatus, value, votingStakingRewardsBalance, isLockedXBE]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isLoading = useMemo(() => {
    if (metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED) {
      return false;
    }

    if(isFirstLoad) {
      if (getBlockXbeLockupInfo === RequestStatus.SUCCESS) {
        setIsFirstLoad(false);
        return false;
      }
      return true;
    }

    return false;
  }, [getBlockXbeLockupInfo, isFirstLoad, metamaskStatus]);

  return (
    <div className={cx(styles.container, className)}>
      <Preloader isLoading={isLoading}>
        <RequirementWalletProvider>
          <PlatformHeaderBlock
            backgroundColor="blue"
            icon="xbe"
            leftText="XBE"
            rightText="LOCKUP"
            classRightText={styles.headerRight}
          />
          <BlockWithList
            theme="whiteShadow"
            className={styles.bottomBlock}
            classNameLabel={styles.listLabel}
            list={list}
          >
            <form>
              <Tooltip
                content={(
                  <Text className={styles.tooltipText}>
                    XBE locked, add more to your lockup below
                  </Text>
                )}
                isTextContent
                isOpenHover
                isOutdoorState={!isLockedXBE}
              >
                <div className={styles.startingWrapper}>
                  <Text className={styles.startingLabel} size="small" color="secondary">Unlock date:</Text>
                  <Text
                    className={styles.startingDate}
                    size="small"
                  >
                    {
                      month ?
                        formattersDate.format(Number(month.value), 'dd / MM / yy') :
                        'Campaign end'
                    }
                  </Text>
                  <Dropdown
                    options={options}
                    value={month}
                    onChange={setMonth}
                    className={styles.startingDropdown}
                  />
                </div>
              </Tooltip>

              <InputWithMaxButton
                value={value}
                onChange={handlerInput}
                onClick={onMaxClick}
                className={styles.input}
                placeholder="0.00 XBE"
                disabled={isLockedXBE}
              />

              <Tooltip
                content={(
                  <Text className={styles.tooltipText}>
                    XBE locked, add more to your lockup below
                  </Text>
                )}
                isTextContent
                isOpenHover
                isOutdoorState={!isLockedXBE}
              >
                <Button
                  isOutline
                  size="mini"
                  onClick={onDepositClick}
                  className={styles.buttonDeposit}
                  disabled={isDepositButtonDisabled}
                >
                  {submitStatus === WriteContractStatus.WRITING ? 'Processing...' : 'Lockup'}
                </Button>
              </Tooltip>
            </form>
            <Text
              color="secondary"
              size="small"
              className={styles.text}
            >
              {/* eslint-disable-next-line max-len */}
              The boost levels shown are for LOCKUP only. Please note that staking while having XBE locked up will reduce your boost
            </Text>
          </BlockWithList>
        </RequirementWalletProvider>
      </Preloader>
    </div>
  );
};

export default XBELockupForm;
