var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import detectEthereumProvider from '@metamask/detect-provider';
import { METAMASK_EXTENSION_URL } from "../appConstants";
import { setNotification } from '.';
const MS_TO_DETECT_METAMASK = 10;
export default function (callback) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const provider = yield detectEthereumProvider({
                timeout: MS_TO_DETECT_METAMASK,
            });
            if (provider) {
                const addresses = yield provider.request({
                    method: 'eth_requestAccounts',
                });
                if (addresses.length) {
                    callback(addresses[0]);
                }
                return;
            }
            window.open(METAMASK_EXTENSION_URL, '_blank');
        }
        catch (err) {
            setNotification({
                type: 'error',
                // @ts-ignore
                message: err.message,
            });
        }
    });
}
