import React, { FC, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import { InputWithAvailable } from 'components';
import { Button, Text, RequestStatus } from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  icon: string,
  token: string,
  name: string,
  maxValue: string,
  withdrawStatus?: RequestStatus,
  onUnStake: (value: string) => void,
};

const DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

const initialValues = {
  withdraw: '',
};

Yup.addMethod(Yup.string, 'withdraw', function (
  errorMessage: string,
  maxWithdraw: string,
) {
  return this.test('test-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.isLessThanOrEqualTo(maxWithdraw),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

const Unstake: FC<Props> = ({
  className,
  icon,
  token,
  name,
  maxValue,
  withdrawStatus,
  onUnStake,
}) => {
  const {
    values,
    setValues,
    isValid,
    handleSubmit,
  } = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      withdraw: Yup.string()
        // @ts-ignore
        .withdraw(
          'Error',
          maxValue,
        )
        .required(),
    }),
    onSubmit: ({ withdraw }) => {
      onUnStake(withdraw);
    },
  });

  const onMaxClick = useCallback(() => {
    if (Number(maxValue) > 0) {
      setValues({ withdraw: maxValue });
    } else {
      setValues({ withdraw: '0' });
    }
  }, [maxValue, setValues]);

  return (
    <form
      onSubmit={handleSubmit}
      className={cx(styles.container, className)}
    >
      <Text
        className={styles.text}
        color="secondary"
      >
        {DESCRIPTION}
      </Text>

      <div className={styles.inner}>
        <InputWithAvailable
          className={styles.inputWithAvailable}
          classNameInput={styles.inputWithAvailableInput}
          icon={icon}
          name={`${name}-input`}
          value={values.withdraw}
          onChange={(event) => setValues({ withdraw: event.target.value })}
          placeholder="0.00"
          isShowAvailableLegend={false}
          available={<span><b>{Number(maxValue).toFixed(2)} {token}</b>  tokens available</span>}
          onMaxClick={onMaxClick}
        />
        <Button
          type="submit"
          className={styles.buttonUnstake}
          isOutline
          size="sm"
          disabled={!isValid || withdrawStatus === RequestStatus.REQUEST}
        >
          { withdrawStatus === RequestStatus.REQUEST ? 'Withdraw...' : 'Unstake & Withdraw' }
        </Button>
      </div>
    </form>
  );
};

export default Unstake;
