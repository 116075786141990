import {
  TOKEN_DECIMALS,
  TOKEN_ADDRESSES,
  Token,
} from 'appConstants';

export const getTokenDecimalByAddress = (address: string) => {
  // eslint-disable-next-line arrow-body-style
  const tokenName = (Object.keys(TOKEN_ADDRESSES) as Token[]).find((key) => {
    return TOKEN_ADDRESSES[key] === address;
  }) as Token;
  return TOKEN_DECIMALS[tokenName];
};
