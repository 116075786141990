/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { XbeLockupActionTypes } from 'store/xbeLockup/actionTypes';
import {
  xbeLockupGetBlockInfoAction,
  xbeLockupSetStateAction,
  xbeLockupSetStatusAction,
} from 'store/xbeLockup/actions';
import { getContract, RequestStatus, setNotification } from '@workstream/shared';
import { veXbeAbi, bonusCampaignAbi } from 'assets/abi';
import { BONUS_CAMPAIGN_CONTRACT_ADDRESS, VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import BigNumber from 'bignumber.js';
import { selectAddress } from 'store/utils';

type LockedResult = {
  0: string,
  1: string,
  amount: string,
  end: string,
};

function* xbeLockupGetBlockInfoSaga(
  { type }: ReturnType<typeof xbeLockupGetBlockInfoAction>,
) {
  try {
    yield put(xbeLockupSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contractBonusCampaign = getContract(bonusCampaignAbi, BONUS_CAMPAIGN_CONTRACT_ADDRESS);
    const contractVeXbe = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);

    const { amount: meLockedAssets }: LockedResult = yield contractVeXbe
      .methods
      .locked(address)
      .call();

    const supply: string = yield contractVeXbe
      .methods
      .supply()
      .call();

    const percentOfTotalStaked = new BigNumber(meLockedAssets).div(supply).toNumber();

    const dateEnd: string = yield contractBonusCampaign
      .methods
      .periodFinish()
      .call();

    yield put(xbeLockupSetStateAction({
      meLockedAssets,
      percentOfTotalStaked: Number.isNaN(percentOfTotalStaked) ? '0' : percentOfTotalStaked.toString(),
      campaignDateEnd: `${dateEnd}000`,
    }));
    yield put(xbeLockupSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location xbelockup');
    yield put(xbeLockupSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    XbeLockupActionTypes.GET_BLOCK_INFO,
    xbeLockupGetBlockInfoSaga,
  );
}
