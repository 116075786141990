import { web3 } from '.';
export default (abi, address) => {
    try {
        const abiFromJson = JSON.parse(JSON.stringify(abi));
        return new web3.eth.Contract(abiFromJson, address);
    }
    catch (error) {
        console.log('abiFromJson', abi);
        console.log('address', address);
        console.log('Error', error);
        throw new Error('error');
    }
};
