import { RequestStatus } from '@workstream/shared';
import { UsdnStakingActionTypes } from 'store/usdnStaking/actionTypes';
import { UsdnStakingState } from 'store/usdnStaking/types';

export const usdnStakingSetStateAction = (payload: Partial<UsdnStakingState>) => ({
  type: UsdnStakingActionTypes.SET_STATE,
  payload,
});

export const usdnStakingSetStatusAction = (
  payload: { type: UsdnStakingActionTypes, status: RequestStatus },
) => ({
  type: UsdnStakingActionTypes.SET_STATUS,
  payload,
});

export const usdnStakingGetBlockInfoAction = () => ({
  type: UsdnStakingActionTypes.GET_BLOCK_INFO,
});
