export default [{
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'from', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'to', type: 'address',
  }, {
    indexed: true, internalType: 'uint256', name: 'amountOfTo', type: 'uint256',
  }],
  name: 'FundsConverted',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
  }],
  name: 'OwnershipTransferred',
  type: 'event',
}, {
  inputs: [], name: 'MAX_BPS', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
},
{
  inputs: [{ internalType: 'address', name: 'account', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
},
{
  inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }], name: 'addTokenToConvert', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'authorized', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_governance', type: 'address' }, { internalType: 'address', name: '_rewardsDistributionRecipientContract', type: 'address' }, { internalType: 'address', name: '_rewardsToken', type: 'address' }, { internalType: 'address', name: '_uniswapRouter', type: 'address' }, { internalType: 'uint256', name: '_slippageTolerance', type: 'uint256' }, { internalType: 'uint256', name: '_swapDeadline', type: 'uint256' }], name: 'configure', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }, { internalType: 'uint256', name: 'amount', type: 'uint256' }], name: 'convertToRewardsToken', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'feeReceiving', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }], name: 'isAllowTokenToConvert', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }], name: 'removeTokenToConvert', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'rewardsDistributionRecipientContract', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'rewardsToken', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_authorized', type: 'address' }, { internalType: 'bool', name: '_status', type: 'bool' }], name: 'setAuthorized', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_rewardsDistributionRecipientContract', type: 'address' }], name: 'setRewardsDistributionRecipientContract', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_rewardsToken', type: 'address' }], name: 'setRewardsToken', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: '_slippageTolerance', type: 'uint256' }], name: 'setSlippageTolerance', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'slippageTolerance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'swapDeadline', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '_tokenAddress', type: 'address' }, { internalType: 'uint256', name: '_amount', type: 'uint256' }], name: 'toGovernance', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'toVoters', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [], name: 'uniswapRouter', outputs: [{ internalType: 'contract IUniswapV2Router02', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}];
