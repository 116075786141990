/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import BigNumber from 'bignumber.js';
import {
  provideLiquidityGetTotalLiquidityAction, provideLiquiditySetStateAction,
  provideLiquiditySetStatusAction,
} from 'store/provideLiquidity/actions';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  ETH_XBE_PAIR_CONTRACT_ADDRESS,
  // SUSHI_VAULT_CONTRACT_ADDRESS,
  WETH_CONTRACT_ADDRESS, XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { sushiVaultAbi, xbeAbi, wethAbi } from 'assets/abi';

function* provideLiquidityGetTotalLiquiditySaga(
  { type }: ReturnType<typeof provideLiquidityGetTotalLiquidityAction>,
) {
  try {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const xbeContract = getContract(xbeAbi, XBE_CONTRACT_ADDRESS);
    const contract = getContract(wethAbi, WETH_CONTRACT_ADDRESS);

    const newSushiswapLpContract = getContract(
      sushiVaultAbi,
      '0xa4D94Ff014c48F1032fe2c558Ec7ECD035276F3b',
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const sushiContract = getContract(sushiVaultAbi, '0x1D46AC355F9f338D9EDEa6C072120abE90D67BeE');

    // const newSushiswapLpContract = getContract(
    //   sushiVaultAbi,
    //   '0xa4D94Ff014c48F1032fe2c558Ec7ECD035276F3b',
    // );

    const stakedLpValue: string = yield newSushiswapLpContract
      .methods
      .totalSupply()
      .call();

    const sushiLpTotalSupply: string = yield sushiContract
      .methods
      .totalSupply()
      .call();

    const totalLiquidity: string = yield xbeContract
      .methods
      .balanceOf(ETH_XBE_PAIR_CONTRACT_ADDRESS)
      .call();

    const totalXBEandEth: string = yield contract
      .methods
      .balanceOf(ETH_XBE_PAIR_CONTRACT_ADDRESS)
      .call();

    yield put(provideLiquiditySetStateAction({
      totalLiquidity: new BigNumber(web3.utils.fromWei(totalLiquidity)).multipliedBy(2).toFixed(2),
      stakedLp: new BigNumber(web3.utils.fromWei(stakedLpValue)).toFixed(),
      sushiswapLp: new BigNumber(web3.utils.fromWei(sushiLpTotalSupply)).toFixed(),
      // sushiswapLpOld: new BigNumber(web3.utils.fromWei(sushiLpTotalSupplyOld)).toFixed(),
      sushiPoolTotalLiquidity: new BigNumber(web3.utils.fromWei(totalXBEandEth))
        .multipliedBy(2).toFixed(2),
    }));
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location providerLiquidityGetTotalLiquiditySaga');
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.GET_TOTAL_LIQUIDITY,
    provideLiquidityGetTotalLiquiditySaga,
  );
}
