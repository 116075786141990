/* eslint-disable no-console */
import { put, takeLeading } from 'redux-saga/effects';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import {
  getContract, setNotification,
} from '@workstream/shared';
import { xbeAbi } from 'assets/abi';
import {
  VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
  XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { xbeStakingSetStateAction } from 'store/xbeStaking/actions';
import { selectAddress } from 'store/utils';

function* xbeStakingGetAllowanceSaga() {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(xbeAbi, XBE_CONTRACT_ADDRESS);
    const allowance: string = yield contract
      .methods
      .allowance(address, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS)
      .call();

    yield put(xbeStakingSetStateAction({ allowance }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getAllowanceSaga');
  }
}

export function* getAllowanceSaga() {
  yield takeLeading(
    XbeStakingActionTypes.GET_ALLOWANCE,
    xbeStakingGetAllowanceSaga,
  );
}
