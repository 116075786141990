import { LocationActionTypes } from 'store/location/actionTypes';
import { LocationState } from 'store/location/types';
import { SetStatusPayload } from 'store/types';

export const locationSetStateAction = (payload: Partial<LocationState>) => ({
  type: LocationActionTypes.SET_STATE,
  payload,
});

export const getLocationAction = () => ({
  type: LocationActionTypes.GET_LOCATION,
});

export const locationSetStatusAction = (
  payload: SetStatusPayload<LocationActionTypes>,
) => ({
  type: LocationActionTypes.SET_STATUS,
  payload,
});
