import createReducer from 'store/createReducer';
import { CrvConvertingState } from 'store/crvConverting/types';
import { CRV_CONVERTING_HANDLERS } from 'store/crvConverting/handlers';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';

const initialState: CrvConvertingState = {
  allowance: '0',
  stakingStatus: WriteContractStatus.INIT,
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  listRewardsContract: [],
  ui: {
    [CrvConvertingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
    [CrvConvertingActionTypes.GET_ALLOWANCE]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, CRV_CONVERTING_HANDLERS);
