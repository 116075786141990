export enum WsEventEnum {
  REGISTER_WALLET = 'message_registration',
}

export enum MetamaskStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  ADDRESS_SELECTED = 'ADDRESS_SELECTED',
}

export enum RequestStatus {
  INIT = 'INIT',
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  RESET = 'RESET',
  ERROR = 'ERROR',
}

export enum BtnTokensText {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
}

export enum BlockIDs {
  currentSupply = 'currentSupply',
  stakeForGovTokens = 'stakeForGovTokens',
}

export enum LiquidityPool {
  ETH_USDT = 'ETH_USDT',
  ETH_XBE = 'ETH_XBE', // pool in sushiswap
  XBE_USDT = 'XBE_USDT',
}

export enum Token {
  USDT = 'usdt',
  XBE = 'xbe',
  EURXB = 'eurxb',
  ETH = 'eth',
  USDC = 'usdc',
  DAI = 'dai',
}

export enum StakingPool {
  uniswap = 'uniswap',
  sushiswap = 'sushiswap',
}
