import React, { FC } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

interface Props {
  width?: number,
  className?: string,
  isMini?: boolean,
  type?: 'app' | 'gov' | 'lockup' | 'finance',
}

const Logo: FC<Props> = ({
  width,
  isMini,
  className,
  type = 'app',
}) => (
  <div
    className={cx(
      isMini && styles.logoMini,
      !isMini && styles.logo,
      !isMini && styles[type],
      className,
    )}
    style={{ width }}
  />
);

export default Logo;
