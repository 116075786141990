import { LocationState } from 'store/location/types';
import { State } from 'store/types';

export default {
  // eslint-disable-next-line max-len
  getProp:
    <T extends keyof LocationState>(propKey: T) => (state: State) => state.location[propKey],
  getState: (state: State) => state.location,
  getStatus:
    <T extends keyof LocationState['ui']>(propKey: T) => (state: State) => state.location.ui[propKey],
};
