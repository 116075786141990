import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import {
  Button, DefaultBlock, Text, RequestStatus,
} from '@workstream/shared';
import { InputWithAvailable } from 'components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BigNumber from 'bignumber.js';
import { RequirementWalletProvider } from 'containers';
import {
  crvConvertingWithdrawAction,
} from 'store/crvConverting/actions';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import { meSelector, useShallowSelector, crvConvertingSelector } from 'store/selectors';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.';

const NOTE = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore elit, sed do eiusmod.';

const initialValues = {
  withdraw: '',
};

Yup.addMethod(Yup.string, 'withdraw', function (
  errorMessage: string,
  maxWithdraw: string,
) {
  return this.test('test-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.isLessThanOrEqualTo(maxWithdraw),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

const Unstake: FC<Props> = ({
  className,
}) => {
  const withdrawStatus = useShallowSelector(
    crvConvertingSelector.getStatus(CrvConvertingActionTypes.WITHDRAW),
  );
  const { cvxCrv: cvxCrvBalance } = useShallowSelector(meSelector.getProp('balances'));

  const dispatch = useDispatch();

  const {
    values,
    setValues,
    isValid,
    handleSubmit,
  } = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      withdraw: Yup.string()
        // @ts-ignore
        .withdraw(
          'Error',
          cvxCrvBalance,
        )
        .required(),
    }),
    onSubmit: ({ withdraw }) => {
      dispatch(crvConvertingWithdrawAction(withdraw));
    },
  });

  const handleChangeWithdraw = useCallback((event) => {
    setValues({ withdraw: event.target.value });
  }, [setValues]);

  const onMaxClick = useCallback(() => {
    if (Number(cvxCrvBalance) > 0) {
      setValues({ withdraw: cvxCrvBalance });
    } else {
      setValues({ withdraw: '0' });
    }
  }, [cvxCrvBalance, setValues]);

  return (
    <RequirementWalletProvider>
      <form
        onSubmit={handleSubmit}
        className={cx(styles.container, className)}
      >
        <div className={styles.left}>
          <Text
            className={styles.description}
            size="small"
            color="secondary"
          >
            {DESCRIPTION}
          </Text>

          <DefaultBlock
            className={styles.noteWrapper}
            theme="lightBlue"
            tag="div"
          >
            <Text
              className={styles.note}
              color="secondary"
              size="small"
            >
              <b>Note: </b>{NOTE}
            </Text>
          </DefaultBlock>
        </div>
        <div className={styles.right}>
          <Text
            className={styles.amountText}
            color="secondary"
            size="small"
          >
            Amount of cvxCRV you wish to unstake:
          </Text>
          <InputWithAvailable
            className={styles.inputWithAvailable}
            name="withdraw cvxCrv"
            value={values.withdraw}
            placeholder="0.00"
            available={`${cvxCrvBalance} cvxCRV`}
            onChange={handleChangeWithdraw}
            onMaxClick={onMaxClick}
          />
          <Button
            type="submit"
            className={styles.button}
            size="sm"
            isOutline
            disabled={!isValid || withdrawStatus === RequestStatus.REQUEST}
          >
            { withdrawStatus === RequestStatus.REQUEST ? 'Unstake...' : 'Unstake' }
          </Button>
        </div>
      </form>
    </RequirementWalletProvider>
  );
};

export default Unstake;
