import React, { FC, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { web3Selector, useShallowSelector, locationSelector } from 'store/selectors';
import { web3SetStateAction } from 'store/web3/actions';
import {
  RequirementWallet,
  handleMetamaskConnect,
  MetamaskStatus,
  setNotification,
} from '@workstream/shared';

type Props = {
  className?: string,
};

const RequirementWalletProvider: FC<PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  const metamaskStatus = useShallowSelector(web3Selector.getProp('metamaskStatus'));

  const country = useSelector(locationSelector.getProp('country'));
  const countryName = useSelector(locationSelector.getProp('countryName'));

  const isInAmerica =
    country.toLowerCase() === 'us' || countryName.toLowerCase() === 'united states';

  const dispatch = useDispatch();

  return (
    <RequirementWallet
      className={className}
      isConnected={
        metamaskStatus === MetamaskStatus.ADDRESS_SELECTED && !isInAmerica
      }
      onConnect={() => (!isInAmerica ?
        handleMetamaskConnect((address) => (
          dispatch(
            web3SetStateAction({
              address,
              metamaskStatus: address
                ? MetamaskStatus.ADDRESS_SELECTED
                : MetamaskStatus.AVAILABLE,
            }),
          )))
        : setNotification({ type: 'error', message: 'Not available to US citizens' }))}
    >
      {children}
    </RequirementWallet>
  );
};

export default RequirementWalletProvider;
