import { Token } from 'appConstants';
import {
  usdnAbi, xbeAbi, eursAbi, wethAbi,
} from 'assets/abi';
import type { Unit } from 'web3-utils';

export const TOKEN_ADDRESSES: Record<Token, string> = {
  xbe: process.env.REACT_APP_XBE_ADDRESS as string,
  eurxb: process.env.REACT_APP_EURXB_ADDRESS as string,
  usdt: process.env.REACT_APP_USDT_CONTRACT_ADDRESS as string,
  eth: process.env.REACT_APP_ETH_CONTRACT_ADDRESS as string,
  usdc: process.env.REACT_APP_USDC_CONTRACT_ADDRESS as string,
  dai: process.env.REACT_APP_DAI_CONTRACT_ADDRESS as string,
};

export const TOKEN_ABIS: Record<Token, object> = {
  xbe: xbeAbi,
  eurxb: eursAbi,
  usdt: usdnAbi,
  eth: wethAbi,
  dai: usdnAbi,
  usdc: usdnAbi,
};

export const TOKEN_DECIMALS: Record<Token, Unit> = {
  xbe: 'ether',
  eurxb: 'ether',
  usdt: 'mwei',
  eth: 'ether',
  usdc: 'mwei',
  dai: 'mwei',
};

export const TOKEN_DECIMALS_NUMBERS: Record<Token, number> = {
  xbe: 18,
  eurxb: 18,
  usdt: 6,
  eth: 18,
  usdc: 6,
  dai: 6,
};
