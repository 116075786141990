import React, { useEffect } from 'react';
import {
  H1, MetamaskStatus,
} from '@workstream/shared';
import cx from 'classnames';
import {
  web3Selector,
  locationSelector,
} from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationAction } from 'store/location/actions';
import { boostGetBlockDepositInfoAction } from 'store/boost/actions';
// import Footer from 'containers/Footer';
import Restricted from 'containers/Restricted';
import RewardsBlocks from 'containers/RewardsBlocks';
import styles from './styles.module.scss';

const Easy = () => {
  const dispatch = useDispatch();
  const metamaskStatus = useSelector(web3Selector.getProp('metamaskStatus'));
  // const metamaskAddress = useSelector(web3Selector.getProp('address'));
  const country = useSelector(locationSelector.getProp('country'));

  const isInAmerica = country.toLowerCase() === 'us';

  useEffect(() => { dispatch(getLocationAction()); }, [dispatch]);

  useEffect(() => {
    if (metamaskStatus === MetamaskStatus.ADDRESS_SELECTED) {
      dispatch(boostGetBlockDepositInfoAction());
    }
  }, [dispatch, metamaskStatus]);

  return (
    <div className={cx('container', styles.easycontainer)}>
      {isInAmerica ? (
        <Restricted />
      ) : (
        <>
          <div className={styles.headerContainer}>
            <div className={cx('container', styles.container)}>
              <H1 className={styles.title}>XBF REWARDS REPORT</H1>
              {/* <Text className={styles.description} color="secondary">
                {'Dear user '} <b>{metamaskAddress}</b>{` Lorem ipsum dolor
                 sit amet, consectetur adipiscing elit, sed do eiusmod
                 tempor incididunt ut labore et dolore magna aliqua.
                 Ut enim ad minim veniam.`}
              </Text> */}
            </div>
          </div>
          <RewardsBlocks />
        </>
      )}
    </div>
  );
};

export default Easy;
