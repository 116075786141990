import { put, takeEvery } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  meGetCurveApysAction, meSetStateAction, meSetStatusAction,
} from 'store/me/actions';
import {
  RequestStatus, setNotification,
} from '@workstream/shared';
import { AxiosResponse } from 'axios';
import BigNumber from 'bignumber.js';
import { apiOld } from 'utils';
import { Curve } from '../constants';
import { MeState } from '../types';

type ApiResponse = {
  [K in Curve]: {
    baseApy: string,
    crvApy: number,
    crvBoost: number,
  }
};

function* meGetCurveApysSaga(
  { type }: ReturnType<typeof meGetCurveApysAction>,
) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const { data }: AxiosResponse<ApiResponse> = yield apiOld.get(
      '/statistics/curve-apys/',
    );

    const curveApys: MeState['curveApys'] = {
      eurt: {
        crvBoost: data.eurt.crvBoost.toFixed(2),
        proj: new BigNumber(data.eurt.baseApy).multipliedBy(data.eurt.crvApy).toFixed(2),
      },
      usdn: {
        crvBoost: data.usdn.crvBoost.toFixed(2),
        proj: new BigNumber(data.usdn.baseApy).multipliedBy(data.usdn.crvApy).toFixed(2),
      },
      steth: {
        crvBoost: data.steth.crvBoost.toFixed(2),
        proj: new BigNumber(data.steth.baseApy).multipliedBy(data.steth.crvApy).toFixed(2),
      },
      cvxcrv: {
        crvBoost: data.cvxcrv.crvBoost.toFixed(2),
        proj: new BigNumber(data.cvxcrv.baseApy).multipliedBy(data.cvxcrv.crvApy).toFixed(2),
      },
      ib: {
        crvBoost: data.ib.crvBoost.toFixed(2),
        proj: new BigNumber(data.ib.baseApy).multipliedBy(data.ib.crvApy).toFixed(2),
      },
    };

    yield put(meSetStateAction({ curveApys }));
    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getCurveApysSaga() {
  yield takeEvery(
    MeActionTypes.GET_CURVE_APYS,
    meGetCurveApysSaga,
  );
}
