/* eslint-disable no-console */
import {
  referralsInfoGetMembersReferralsAction,
  referralsInfoSetStateAction,
  referralsInfoSetStatusAction,
} from 'store/referrals/info/actions';
import { ReferralsInfoState } from 'store/referrals/info/types';
import { put, takeEvery } from 'redux-saga/effects';
import { ReferralsInfoActionTypes } from 'store/referrals/info/actionTypes';
import { RequestStatus, setNotification, formattersDate } from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { AxiosResponse } from 'axios';
import { api } from 'utils';

type Result = ReferralsInfoState['membersReferrals'];

function* referralsInfoGetMembersReferralsSaga(
  { type }: ReturnType<typeof referralsInfoGetMembersReferralsAction>,
) {
  try {
    yield put(referralsInfoSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const result: AxiosResponse<Result> = yield api.get(`/users/user/member-liquidity?address=${address}`);

    const { data } = result;

    if (data.length) {
      const formatterDateNow = formattersDate.format(Date.now(), 'dd/MM/yy');

      const membersReferrals: ReferralsInfoState['membersReferrals'] = data.map((item) => {
        const date = new Date(item.date);
        const formatterDate = formattersDate.format(date, 'dd/MM/yy');

        return {
          ...item,
          date: formattersDate.format(date, formatterDateNow === formatterDate ? 'hh:mm' : 'dd MMMM ‘yy'),
        };
      });

      yield put(referralsInfoSetStateAction({
        membersReferrals,
      }));
    }
    yield put(referralsInfoSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getMembersReferralsSaga');
    yield put(referralsInfoSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getMembersReferralsSaga() {
  yield takeEvery(
    ReferralsInfoActionTypes.GET_MEMBERS_REFFERALS,
    referralsInfoGetMembersReferralsSaga,
  );
}
