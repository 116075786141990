import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import createReducer from 'store/createReducer';
import { StakeCurveLpState } from './types';
import { STAKE_CURVE_LP_HANDLERS } from './handlers';
import { CurveLpToken } from './constants';

const initialState: StakeCurveLpState = {
  allowances: {
    [CurveLpToken.USDN]: '0',
    [CurveLpToken.IRON_BANK]: '0',
    [CurveLpToken.ST_ETH]: '0',
    [CurveLpToken.EURS]: '0',
  },
  balances: {
    [CurveLpToken.USDN]: '0',
    [CurveLpToken.IRON_BANK]: '0',
    [CurveLpToken.ST_ETH]: '0',
    [CurveLpToken.EURS]: '0',
  },
  balancesVaults: {
    [CurveLpToken.USDN]: '0',
    [CurveLpToken.IRON_BANK]: '0',
    [CurveLpToken.ST_ETH]: '0',
    [CurveLpToken.EURS]: '0',
  },
  uiWithdrawStatus: {
    [CurveLpToken.USDN]: RequestStatus.INIT,
    [CurveLpToken.IRON_BANK]: RequestStatus.INIT,
    [CurveLpToken.ST_ETH]: RequestStatus.INIT,
    [CurveLpToken.EURS]: RequestStatus.INIT,
  },
  listRewardsContractsAddresses: {
    [CurveLpToken.USDN]: [],
    [CurveLpToken.IRON_BANK]: [],
    [CurveLpToken.ST_ETH]: [],
    [CurveLpToken.EURS]: [],
  },
  lpTokensAddresses: {
    [CurveLpToken.USDN]: '',
    [CurveLpToken.IRON_BANK]: '',
    [CurveLpToken.ST_ETH]: '',
    [CurveLpToken.EURS]: '',
  },
  uiSubmitStatus: {
    [CurveLpToken.USDN]: WriteContractStatus.INIT,
    [CurveLpToken.IRON_BANK]: WriteContractStatus.INIT,
    [CurveLpToken.ST_ETH]: WriteContractStatus.INIT,
    [CurveLpToken.EURS]: WriteContractStatus.INIT,
  },
  ui: {},
};

export default createReducer(initialState, STAKE_CURVE_LP_HANDLERS);
