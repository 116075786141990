import { RequestStatus } from '@workstream/shared';
import { EursStakingActionTypes } from 'store/eursStaking/actionTypes';
import { EursStakingState } from 'store/eursStaking/types';

export const eursStakingSetStateAction = (payload: Partial<EursStakingState>) => ({
  type: EursStakingActionTypes.SET_STATE,
  payload,
});

export const eursStakingSetStatusAction = (
  payload: { type: EursStakingActionTypes, status: RequestStatus },
) => ({
  type: EursStakingActionTypes.SET_STATUS,
  payload,
});

export const eursStakingGetBlockInfoAction = () => ({
  type: EursStakingActionTypes.GET_BLOCK_INFO,
});
