/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  getContract, RequestStatus, setNotification,
} from '@workstream/shared';
import { meSetStatusAction, meGetIsLockedXbeAction, meSetStateAction } from 'store/me/actions';
import { veXbeAbi } from 'assets/abi';
import { VE_XBE_CONTRACT_ADDRESS } from 'appConstants/contracts';
import BigNumber from 'bignumber.js';
import { selectAddress } from 'store/utils';

type LockedResult = {
  0: string,
  1: string,
  amount: string,
  end: string,
};

function* meGetIsLockedXbeSaga(
  { type }: ReturnType<typeof meGetIsLockedXbeAction>,
) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);

    const { amount, end }: LockedResult = yield contract
      .methods
      .locked(address)
      .call();

    const amountBN = new BigNumber(amount);
    const endBN = new BigNumber(end);

    if (amountBN.isGreaterThan('0') || endBN.isGreaterThan('0')) {
      yield put(meSetStateAction({ isLockedXBE: true }));
    } else {
      yield put(meSetStateAction({ isLockedXBE: false }));
    }

    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getIsLockedXbeSaga');
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getIsLockedXbeSaga() {
  yield takeEvery(
    MeActionTypes.GET_IS_LOCKED_XBE,
    meGetIsLockedXbeSaga,
  );
}
