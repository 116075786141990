import { SetStatusPayload } from 'store/types';
import { StakeCurveLpActionTypes } from './actionTypes';
import { ListRewardsContractsAddresses, StakeCurveLpState } from './types';
import { CurveLpToken } from './constants';

export const stakeCurveLpSetStateAction = (payload: Partial<StakeCurveLpState>) => ({
  type: StakeCurveLpActionTypes.SET_STATE,
  payload,
});

export const stakeCurveLpSetStatusAction = (
  payload: SetStatusPayload<StakeCurveLpActionTypes>,
) => ({
  type: StakeCurveLpActionTypes.SET_STATUS,
  payload,
});

export const stakeCurveLpSetBalanceAction = (
  payload: { token: CurveLpToken, balance: string },
) => ({
  type: StakeCurveLpActionTypes.SET_BALANCE,
  payload,
});

export const stakeCurveLpGetBalanceAction = (payload: CurveLpToken) => ({
  type: StakeCurveLpActionTypes.GET_BALANCE,
  payload,
});

export const stakeCurveLpSetBalanceVaultAction = (
  payload: { token: CurveLpToken, balance: string },
) => ({
  type: StakeCurveLpActionTypes.SET_BALANCE_VAULT,
  payload,
});

export const stakeCurveLpGetBalanceVaultAction = (payload: CurveLpToken) => ({
  type: StakeCurveLpActionTypes.GET_BALANCE_VAULT,
  payload,
});

export const stakeCurveLpApproveAction = (payload: { token: CurveLpToken, value: string }) => ({
  type: StakeCurveLpActionTypes.APPROVE,
  payload,
});

export const stakeCurveLpSetSubmitStatusAction = (
  payload: Partial<StakeCurveLpState['uiSubmitStatus']>,
) => ({
  type: StakeCurveLpActionTypes.SET_SUBMIT_STATUS,
  payload,
});

export const stakeCurveLpGetAllowanceAction = (payload: CurveLpToken) => ({
  type: StakeCurveLpActionTypes.GET_ALLOWANCE,
  payload,
});

export const stakeCurveLpSetAllowanceAction = (
  payload: { token: CurveLpToken, allowance: string },
) => ({
  type: StakeCurveLpActionTypes.SET_ALLOWANCE,
  payload,
});

export const stakeCurveLpDepositAction = (
  payload: { token: CurveLpToken, value: string },
) => ({
  type: StakeCurveLpActionTypes.DEPOSIT,
  payload,
});

export const stakeCurveLpWithdrawAction = (
  payload: { token: CurveLpToken, value: string },
) => ({
  type: StakeCurveLpActionTypes.WITHDRAW,
  payload,
});

export const stakeCurveLpSetWithdrawStatusAction = (
  payload: Partial<StakeCurveLpState['uiWithdrawStatus']>,
) => ({
  type: StakeCurveLpActionTypes.SET_WITHDRAW_STATUS,
  payload,
});

export const stakeCurveLpGetRewardsContractsAddressesAction = (payload: CurveLpToken) => ({
  type: StakeCurveLpActionTypes.GET_REWARDS_CONTRACTS_ADDRESSES,
  payload,
});

export const stakeCurveLpSetRewardsContractsAddressesAction = (
  payload: { token: CurveLpToken, list: ListRewardsContractsAddresses },
) => ({
  type: StakeCurveLpActionTypes.SET_REWARDS_CONTRACTS_ADDRESSES,
  payload,
});

export const stakeCurveLpGetLpTokenAddressAction = (payload: CurveLpToken) => ({
  type: StakeCurveLpActionTypes.GET_LP_TOKEN_ADDRESS,
  payload,
});

export const stakeCurveLpSetLpTokenAddressAction = (
  payload: { token: CurveLpToken, address: string },
) => ({
  type: StakeCurveLpActionTypes.SET_LP_TOKEN_ADDRESS,
  payload,
});
