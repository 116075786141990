import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  BlockWrapper,
  Button,
  Copyable,
  handleMetamaskConnect,
  Icon,
  MetamaskStatus,
  Text,
  setNotification,
} from '@workstream/shared';
import { useDispatch, useSelector } from 'react-redux';
import { web3SetStateAction } from 'store/web3/actions';
import { web3Selector, locationSelector } from 'store/selectors';
import cx from 'classnames';
import Logo from '../../components/Logo';
import styles from './styles.module.scss';

interface Props {
  setEasy2Active: () => void;
  setEasyActive: () => void;
  activeTab: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Header = ({ setEasyActive, setEasy2Active, activeTab }: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const metamaskAddress = useSelector(web3Selector.getProp('address'));
  const country = useSelector(locationSelector.getProp('country'));

  const isInAmerica = country.toLowerCase() === 'us';
  const dispatch = useDispatch();

  const resizeListener = () => {
    if (window.screen.width > 979) {
      setIsOpenMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  useEffect(() => {
    const root = document.querySelector('#root');
    if (isOpenMenu) {
      root!.classList.add('openMenu');
    } else {
      root!.classList.remove('openMenu');
    }
  }, [isOpenMenu]);

  const onConnectClick = () => (!isInAmerica
    ? handleMetamaskConnect((address) => dispatch(
      web3SetStateAction({
        metamaskStatus: MetamaskStatus.ADDRESS_SELECTED,
        address,
      }),
    ))
    : setNotification({
      type: 'error',
      message: 'Not available to US citizens',
    }));

  return (
    <BlockWrapper tag="header" className={styles.header}>
      <a
        className={styles.logoLink}
        href="https://xbe.finance/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Logo type="finance" />
      </a>
      <div className={cx(styles.menu, isOpenMenu && styles.open)}>
        {isInAmerica ? null : (
          <ul className={styles.menuList}>
            <li className={styles.menuItem}>
              <NavLink
                to="/"
                className={styles.link}
                onClick={() => {
                  window.open('https://app.xbe.finance', '_blank');
                }}
                activeClassName={styles.linkActive}
                isActive={(match, location) => {
                  if (location.pathname === '/lockup') {
                    return true;
                  }

                  return false;
                }}
              >
                XBE & veXBE
              </NavLink>
            </li>
          </ul>
        )}
        {isInAmerica ? null : (
          <Button
            className={styles.backButton}
            isOutline
            onClick={() => window.open('https://app.xbe.finance/lockup', '_self')}
          >
            Back to app
          </Button>
        )}
        {metamaskAddress && !isInAmerica ? (
          <Copyable valueToCopy={metamaskAddress}>
            <Button
              isOutline={false}
              color="white"
              className={styles.buttonAddress}
            >
              <Text size="large" tag="span" bold className={styles.address}>
                {metamaskAddress}
              </Text>
              <Icon icon="share" />
            </Button>
          </Copyable>
        ) : (
          <Button
            color="tealDark"
            onClick={
              isInAmerica
                ? () => window.open('https://xbe.finance/', '_self')
                : onConnectClick
            }
            className={`${isInAmerica ? styles.marginLeft : ''} ${
              styles.walletButton
            }`}
          >
            {isInAmerica ? 'Back to Site' : 'Connect wallet'}
          </Button>
        )}
      </div>
      <button
        type="button"
        className={styles.buttonOpenMenu}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
      >
        <i className={cx(styles.line, styles.line_1)} />
        <i className={cx(styles.line, styles.line_2)} />
        <i className={cx(styles.line, styles.line_3)} />
      </button>
    </BlockWrapper>
  );
};

export default Header;
