import createReducer from 'store/createReducer';
import { EursStakingState } from 'store/eursStaking/types';
import { EURS_STAKING_HANDLERS } from 'store/eursStaking/handlers';
import { RequestStatus } from '@workstream/shared';
import { EursStakingActionTypes } from 'store/eursStaking/actionTypes';

const initialState: EursStakingState = {
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [EursStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, EURS_STAKING_HANDLERS);
