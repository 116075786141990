import { PartialRecord, RequestStatus } from '@workstream/shared';
import { RateActionTypes } from 'store/rate/actionTypes';

export enum RatesToken {
  XBE = 'XBE',
  ETH = 'ETH',
}

export enum VenuesToken {
  coinmarketcap = 'coinmarketcap',
  uniswap = 'uniswap',
  sushiswap = 'sushiswap',
}

export enum RatesCurrencyToken {
  USDT = 'USDT',
  USD = 'USD',
}

export type RatesTokenBody = {
  venue: string,
  quote: string,
  quantity: string,
};

export type RatesTokensBody = PartialRecord<RatesCurrencyToken, RatesTokenBody>;

export type RateState = Record<RatesToken, RatesTokensBody> & {
  ui: PartialRecord<RateActionTypes, RequestStatus>,
};
