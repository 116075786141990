/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, takeEvery } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  meGetXBFRewardsAction,
  meSetXBFRewardsAction,
  meSetStatusAction,
} from 'store/me/actions';
import { RequestStatus, setNotification } from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { AxiosResponse } from 'axios';
import { xbfApi } from 'utils';
import { XBFApiResponse } from '../types';

function* meGetXBFRewards({
  type,
}: ReturnType<typeof meGetXBFRewardsAction>) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    // // @ts-ignore
    const { data }: AxiosResponse<XBFApiResponse> = yield xbfApi.get(
      `wallet/${address}`,
    );

    // // @ts-ignore
    // yield fetch(
    //   'https://api-staging-xbf-rewards-report-5hr8v.ondigitalocean.app/wallet/0x16e7b79448CE012B898dfb8D79228E6A1D1d9B2F',
    // )
    //   .then((res) => res.json())
    //   .then((result) => {
    //     data = result;
    //     // eslint-disable-next-line no-console
    //     console.log('fetch result', data);
    //   });

    // eslint-disable-next-line no-console
    // console.log('fetch result', data);

    yield put(meSetXBFRewardsAction(data));

    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getXBFRewardsSaga() {
  yield takeEvery(MeActionTypes.GET_XBF_REWARDS, meGetXBFRewards);
}
