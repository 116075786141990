import {
  EURS_CONTRACT_ADDRESS,
  IRON_BANK_CONTRACT_ADDRESS,
  ST_ETH_CONTRACT_ADDRESS,
  USDN_CONTRACT_ADDRESS,
  VAULT_EURS_CONTRACT_ADDRESS,
  VAULT_IRON_BANK_CONTRACT_ADDRESS,
  VAULT_ST_ETH_CONTRACT_ADDRESS,
  VAULT_USDN_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import {
  eursAbi,
  ironBankAbi, stEthAbi, usdnAbi, vaultEursAbi, vaultIronBankAbi, vaultStEthAbi, vaultUsdnAbi,
} from 'assets/abi';
import { Unit } from 'web3-utils';

export enum CurveLpToken {
  IRON_BANK = 'ironBank',
  USDN = 'usdn',
  ST_ETH = 'stEth',
  EURS = 'eurs',
}

export const stakeCurveLpTokensAddress: Record<CurveLpToken, string> = {
  [CurveLpToken.IRON_BANK]: IRON_BANK_CONTRACT_ADDRESS,
  [CurveLpToken.USDN]: USDN_CONTRACT_ADDRESS,
  [CurveLpToken.ST_ETH]: ST_ETH_CONTRACT_ADDRESS,
  [CurveLpToken.EURS]: EURS_CONTRACT_ADDRESS,
};

export const stakeCurveLpTokensAbi: Record<CurveLpToken, any> = {
  [CurveLpToken.IRON_BANK]: ironBankAbi,
  [CurveLpToken.USDN]: usdnAbi,
  [CurveLpToken.ST_ETH]: stEthAbi,
  [CurveLpToken.EURS]: eursAbi,
};

export const stakeCurveLpTokensDecimals: Record<CurveLpToken, Unit> = {
  [CurveLpToken.IRON_BANK]: 'ether',
  [CurveLpToken.USDN]: 'ether',
  [CurveLpToken.ST_ETH]: 'ether',
  [CurveLpToken.EURS]: 'ether',
};

export const stakeCurveLpVaultTokensAddress: Record<CurveLpToken, string> = {
  [CurveLpToken.IRON_BANK]: VAULT_IRON_BANK_CONTRACT_ADDRESS,
  [CurveLpToken.USDN]: VAULT_USDN_CONTRACT_ADDRESS,
  [CurveLpToken.ST_ETH]: VAULT_ST_ETH_CONTRACT_ADDRESS,
  [CurveLpToken.EURS]: VAULT_EURS_CONTRACT_ADDRESS,
};

export const stakeCurveLpVaultTokensAbi: Record<CurveLpToken, any> = {
  [CurveLpToken.IRON_BANK]: vaultIronBankAbi,
  [CurveLpToken.USDN]: vaultUsdnAbi,
  [CurveLpToken.ST_ETH]: vaultStEthAbi,
  [CurveLpToken.EURS]: vaultEursAbi,
};

export const stakeCurveLpVaultTokensDecimals: Record<CurveLpToken, Unit> = {
  [CurveLpToken.IRON_BANK]: 'ether',
  [CurveLpToken.USDN]: 'ether',
  [CurveLpToken.ST_ETH]: 'ether',
  [CurveLpToken.EURS]: 'ether',
};
