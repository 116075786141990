var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState, useEffect } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { setNotification } from "../utils";
import { ProviderLoadingStatus, MetamaskStatus } from "../appConstants";
const DEFAULT_PROVIDER = {
    metamaskStatus: MetamaskStatus.NOT_AVAILABLE,
    account: null,
    chainID: null,
};
export default function ({ onSetProvider = () => { }, onAccountChange = () => { }, onChainChange = () => { }, }) {
    const [loadingStatus, setLoadingStatus] = useState(ProviderLoadingStatus.INIT);
    const handleProvider = useCallback((provider) => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoadingStatus(ProviderLoadingStatus.LOADING);
            if (provider) {
                const res = yield provider.request({
                    method: "eth_accounts",
                });
                const chainID = yield provider.request({
                    method: "eth_chainId",
                });
                const account = (res && res.length && res[0]) || null;
                setLoadingStatus(ProviderLoadingStatus.LOADED);
                onSetProvider({
                    account,
                    metamaskStatus: account
                        ? MetamaskStatus.ADDRESS_SELECTED
                        : MetamaskStatus.AVAILABLE,
                    chainID,
                });
                return;
            }
            setLoadingStatus(ProviderLoadingStatus.LOADED);
            onSetProvider(DEFAULT_PROVIDER);
        }
        catch (err) {
            setNotification({
                type: "error",
                // @ts-ignore
                message: err.message,
            });
            setLoadingStatus(ProviderLoadingStatus.LOADED);
            onSetProvider(DEFAULT_PROVIDER);
        }
    }), []);
    const handleAccountChange = useCallback((provider) => {
        try {
            if (provider) {
                provider.on("accountsChanged", (accounts) => {
                    if (accounts.length) {
                        onAccountChange(accounts[0]);
                    }
                    else {
                        onAccountChange(null);
                    }
                });
            }
        }
        catch (err) {
            setNotification({
                type: "error",
                // @ts-ignore
                message: err.message,
            });
        }
    }, []);
    const handleChainChange = useCallback((provider) => {
        try {
            if (provider) {
                provider.on("chainChanged", (value) => onChainChange(value));
            }
        }
        catch (err) {
            setNotification({
                type: "error",
                // @ts-ignore
                message: err.message,
            });
        }
    }, []);
    useEffect(() => {
        function metamaskListener() {
            return __awaiter(this, void 0, void 0, function* () {
                const provider = yield detectEthereumProvider();
                yield handleProvider(provider);
                handleAccountChange(provider);
                handleChainChange(provider);
            });
        }
        metamaskListener();
    }, []);
    return {
        loadingStatus,
    };
}
