import { put, select, takeEvery } from 'redux-saga/effects';
import {
  getContract,
  setNotification,
  web3,
  WriteContractStatus,
} from '@workstream/shared';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';
import {
  sushiSwapLPStakingDepositAction,
  sushiSwapLPStakingGetBlockInfoAction,
  sushiSwapLPStakingSetStateAction,
} from 'store/sushiSwapLPStaking/actions';
import { hiveVaultAbi } from 'assets/abi';
import { SUSHI_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { web3Selector } from 'store/selectors';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';

function* sushiSwapLPStakingDepositSaga(
  { payload: value }: ReturnType<typeof sushiSwapLPStakingDepositAction>,
) {
  try {
    yield put(sushiSwapLPStakingSetStateAction({ submitStatus: WriteContractStatus.WRITING }));
    const address: string = yield select(web3Selector.getProp('address'));
    const contract = getContract(hiveVaultAbi, SUSHI_VAULT_CONTRACT_ADDRESS);

    yield contract
      .methods
      .deposit(web3.utils.toWei(value))
      .send({
        from: address,
      });

    yield put(sushiSwapLPStakingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.SUSHI_VAULT));
    yield put(meGetBalancesAction(BalancesToken.ETH_XBE));

    yield put(sushiSwapLPStakingSetStateAction({ submitStatus: WriteContractStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(sushiSwapLPStakingSetStateAction({ submitStatus: WriteContractStatus.ERROR }));
  }
}

export function* depositSaga() {
  yield takeEvery(
    SushiSwapLPStakingActionTypes.DEPOSIT,
    sushiSwapLPStakingDepositSaga,
  );
}
