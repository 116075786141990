import { put, takeEvery } from 'redux-saga/effects';
import { RequestStatus, setNotification } from '@workstream/shared';
import {
  claimClaimAction, claimClaimCvxCrvAction, claimGetStakeAction,
  claimSetStatusAction,
} from 'store/claim/actions';
import { ClaimActionTypes } from 'store/claim/actionTypes';
import { ClaimTokens } from 'store/claim/constants';

function* claimClaimCvxCrvSaga(
  { type }: ReturnType<typeof claimClaimCvxCrvAction>,
) {
  try {
    yield put(claimSetStatusAction({ type, status: RequestStatus.REQUEST }));
    yield put(claimClaimAction({ token: ClaimTokens.CVX_CRV, claimUnderlying: false }));
    yield put(claimGetStakeAction(ClaimTokens.CVX_CRV));
    yield put(claimSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(claimSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* claimCvxCrvSaga() {
  yield takeEvery(
    ClaimActionTypes.CLAIM_CVX_CRV,
    claimClaimCvxCrvSaga,
  );
}
