import createReducer from 'store/createReducer';
import { XbeLockupState } from 'store/xbeLockup/types';
import { XBE_LOCKUP_HANDLERS } from 'store/xbeLockup/handlers';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';
import { XbeLockupActionTypes } from 'store/xbeLockup/actionTypes';

const initialState: XbeLockupState = {
  meLockedAssets: '0',
  percentOfTotalStaked: '0',
  campaignDateEnd: '0',
  submitStatus: WriteContractStatus.INIT,
  ui: {
    [XbeLockupActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, XBE_LOCKUP_HANDLERS);
