import createReducer from 'store/createReducer';
import { BoostState } from 'store/boost/types';
import { BOOST_HANDLERS } from 'store/boost/handlers';
import { BoostActionTypes } from 'store/boost/actionTypes';
import { RequestStatus, WriteContractStatus } from '@workstream/shared';

const initialState: BoostState = {
  totalXBEVoteLocked: '0',
  ofAllCirculatingXBE: '0',
  totalVeXBE: '0',
  averageLockTime: '0',
  startingVotingPower: '0',
  myXBELocked: '0',
  campaignDateEnd: '0',
  meLockedDateEnd: '0',
  hasMaxBoostLevel: false,
  submitStatus: WriteContractStatus.INIT,
  ui: {
    [BoostActionTypes.GET_BLOCK_DEPOSIT_INFO]: RequestStatus.INIT,
    [BoostActionTypes.SUBMIT_LOCK_TIME]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, BOOST_HANDLERS);
