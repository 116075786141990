import { HandlerFn } from 'store/types';
import { EurXbStakingActionTypes } from 'store/eurXbStaking/actionTypes';
import { EurXbStakingState } from 'store/eurXbStaking/types';
import { eurXbStakingSetStateAction, eurXbStakingSetStatusAction } from 'store/eurXbStaking/actions';

type EurXbStakingActionFn<F extends (...args: any) => any>
  = HandlerFn<EurXbStakingState, ReturnType<F>>;

const setState: EurXbStakingActionFn<typeof eurXbStakingSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: EurXbStakingActionFn<typeof eurXbStakingSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const EUR_XB_STAKING_HANDLERS = {
  [EurXbStakingActionTypes.SET_STATE]: setState,
  [EurXbStakingActionTypes.SET_STATUS]: setStatus,
};
