import { routes } from './index';

export const NAV_LINKS_HEADER = [
  {
    link: routes.easy.root,
    title: routes.easy.title,
  },
  {
    link: routes.lockup.root,
    title: routes.lockup.title,
  },
  // {
  //   link: routes.stakeCurve.root,
  //   title: routes.stake.title,
  // },
  // {
  //   link: routes.claim.root,
  //   title: routes.claim.title,
  // },
  // {
  //   link: routes.referrals.root,
  //   title: routes.referrals.title,
  // },
];

// export const NAV_LINKS_STAKE = [
//   {
//     link: routes.stakeCurve.root,
//     title: routes.stakeCurve.title,
//   },
//   {
//     link: routes.convertCrv.root,
//     title: routes.convertCrv.title,
//   },
//   {
//     link: routes.stakeOther.root,
//     title: routes.stakeOther.title,
//   },
// ];
