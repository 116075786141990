/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { UsdnStakingActionTypes } from 'store/usdnStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  usdnStakingSetStateAction,
  usdnStakingSetStatusAction,
  usdnStakingGetBlockInfoAction,
} from 'store/usdnStaking/actions';
import {
  RequestStatus, setNotification,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';

function* usdnStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof usdnStakingGetBlockInfoAction>,
) {
  try {
    yield put(usdnStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=usdnVault`);

    yield put(usdnStakingSetStateAction({
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
    }));
    yield put(usdnStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location usdnStakingGetBlockInfoSaga');
    yield put(usdnStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    UsdnStakingActionTypes.GET_BLOCK_INFO,
    usdnStakingGetBlockInfoSaga,
  );
}
