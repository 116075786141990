/* eslint-disable @typescript-eslint/quotes */

export default {
  easy: {
    root: '/',
    title: 'Easy XBE',
  },
  lockup: {
    root: '/lockup',
    title: 'XBE & veXBE',
  },
  // stake: {
  //   root: '/stake',
  //   title: 'Stake',
  // },
  // claim: {
  //   root: '/claim',
  //   title: 'Claim',
  // },
  // referrals: {
  //   root: '/referrals',
  //   title: 'Referrals',
  // },
  // stakeCurve: {
  //   root: '/stake/stakeCure',
  //   title: 'STAKE CURVE LP TOKENS',
  // },
  // convertCrv: {
  //   root: '/stake/convertCrv',
  //   title: 'CONVERT CRV',
  // },
  // stakeOther: {
  //   root: '/stake/stakeOther',
  //   title: 'Stake CVX, cvxCRV & XBE',
  // },
};
