import { HandlerFn } from 'store/types';
import { MeActionTypes } from 'store/me/actionTypes';
import { MeState } from 'store/me/types';
import {
  meSetStateAction,
  meSetStatusAction,
  meBalancesSetStatusAction,
  meSetBalanceAction,
  meSetXBFRewardsAction,
} from 'store/me/actions';

type MeActionFn<F extends (...args: any) => any> = HandlerFn<MeState, ReturnType<F>>;

const setState: MeActionFn<typeof meSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: MeActionFn<typeof meSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

const setBalancesStatus: MeActionFn<typeof meBalancesSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  uiBalances: {
    ...state.uiBalances,
    ...payload,
  },
});

const setBalance: MeActionFn<typeof meSetBalanceAction> = (
  state,
  { payload },
) => ({
  ...state,
  balances: {
    ...state.balances,
    ...payload,
  },
});

const setXBFRewards: MeActionFn<typeof meSetXBFRewardsAction> = (
  state,
  { payload },
) => ({
  ...state,
  xbfRewards: {
    ...state.xbfRewards,
    ...payload,
  },
});

export const ME_HANDLERS = {
  [MeActionTypes.SET_STATE]: setState,
  [MeActionTypes.SET_STATUS]: setStatus,
  [MeActionTypes.SET_BALANCES_STATUS]: setBalancesStatus,
  [MeActionTypes.SET_BALANCE]: setBalance,
  [MeActionTypes.SET_XBF_REWARDS]: setXBFRewards,
};
