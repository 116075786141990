import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

export type Props = {
  className?: string;
  icon?: string;
  text: string;
  value: string;
  valueClassName?: string;
  textClassName?: string;
  iconClassName?: string;
};

const ClaimBlock: FC<Props> = ({
  className,
  icon,
  text,
  value,
  valueClassName,
  iconClassName,
  textClassName,
}) => (
  <div className={cx(styles.earned, className)}>
    {icon ?
      <img src={icon} alt={icon} className={iconClassName} />
      : null}
    <div className={styles.earnedContent}>
      <span className={cx(styles.earnedText, textClassName)}>{text}</span>
      <span className={cx(styles.earnedValue, valueClassName)}>{value}</span>
    </div>
  </div>
);

export default ClaimBlock;
