export enum MeActionTypes {
  SET_STATE = 'ME.SET_STATE',

  SET_STATUS = 'ME.SET_STATUS',

  GET_BALANCES = 'ME.GET_BALANCES',
  GET_BALANCE_ETH = 'ME.GET_BALANCE_ETH',
  SET_BALANCES_STATUS = 'ME.SET_BALANCES_STATUS',
  SET_BALANCE = 'ME.SET_BALANCE',

  GET_BOOST_LEVEL = 'ME.GET_BOOST_LEVEL',

  GET_IS_LOCKED_XBE = 'ME.GET_IS_LOCKED_XBE',

  GET_CURVE_APYS = 'ME.GET_CURVE_APYS',

  GET_IS_SIGNED = 'ME.GET_IS_SIGNED',

  SET_SIGNED_STATUS = 'ME.SET_SIGNED_STATUS',
  SET_SIGNED = 'ME.SET_SIGNED',

  GET_XBF_REWARDS = 'ME.GET_XBF_REWARDS',
  SET_XBF_REWARDS = 'ME.SET_XBF_REWARDS',
}
