/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { put, takeEvery, call } from 'redux-saga/effects';
import { MeActionTypes } from 'store/me/actionTypes';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import {
  meSetStateAction,
  meGetBalanceEthAction,
  meSetStatusAction,
} from 'store/me/actions';
import { Contract } from 'web3-eth-contract';
import { selectAddress } from 'store/utils';
import {
  Token, TOKEN_ABIS, TOKEN_ADDRESSES, TOKEN_DECIMALS,
} from 'appConstants';

function* getBalanceByToken(token: Token, account: string) {
  const tokenContract: Contract = yield call(
    getContract,
    TOKEN_ABIS[token],
    TOKEN_ADDRESSES[token],
  );
  const balance: string = yield tokenContract.methods.balanceOf(account).call();
  return web3.utils.fromWei(balance, TOKEN_DECIMALS[token]);
}

function* meGetBalanceEthSaga({ type }: ReturnType<typeof meGetBalanceEthAction>) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const result: string = yield web3.eth.getBalance(address);

    const usdc: string = yield getBalanceByToken(Token.USDC, address);
    const dai: string = yield getBalanceByToken(Token.DAI, address);

    yield put(meSetStateAction({
      eth: web3.utils.fromWei(result),
      usdc,
      dai,
    }));
    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (err) {
    setNotification({ type: 'error', message: err.message });
    console.log('error', err.message);
    console.log('error location meGEtBalanceEthSaga');
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    MeActionTypes.GET_BALANCE_ETH,
    meGetBalanceEthSaga,
  );
}
