/* eslint-disable no-console */
import { put, takeLeading } from 'redux-saga/effects';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  stakeCurveLpWithdrawAction,
  stakeCurveLpSetWithdrawStatusAction,
  stakeCurveLpGetAllowanceAction,
  stakeCurveLpGetBalanceAction,
  stakeCurveLpGetBalanceVaultAction,
} from 'store/stakeCurveLp/actions';
import {
  stakeCurveLpVaultTokensAbi,
  stakeCurveLpVaultTokensAddress,
} from 'store/stakeCurveLp/constants';

function* stakeCurveLpWithdrawSaga(
  { payload: { token, value } }: ReturnType<typeof stakeCurveLpWithdrawAction>,
) {
  try {
    yield put(stakeCurveLpSetWithdrawStatusAction({ [token]: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const contract = getContract(
      stakeCurveLpVaultTokensAbi[token],
      stakeCurveLpVaultTokensAddress[token],
    );

    yield contract
      .methods
      .withdraw(web3.utils.toWei(value))
      .send({
        from: address,
      });

    yield put(stakeCurveLpGetAllowanceAction(token));
    yield put(stakeCurveLpGetBalanceAction(token));
    yield put(stakeCurveLpGetBalanceVaultAction(token));
    yield put(stakeCurveLpSetWithdrawStatusAction({ [token]: RequestStatus.SUCCESS }));

    setNotification({ type: 'success', message: 'Withdraw success!' });
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location withdrawSaga');
    yield put(stakeCurveLpSetWithdrawStatusAction({ [token]: RequestStatus.ERROR }));
  }
}

export function* withdrawSaga() {
  yield takeLeading(
    StakeCurveLpActionTypes.WITHDRAW,
    stakeCurveLpWithdrawSaga,
  );
}
