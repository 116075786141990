import React, { useEffect } from 'react';
import { Routes, Web3Provider, InitializeActionsProvider } from 'containers';
import { ToastProvider } from '@workstream/shared';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { wsConnectAction } from 'store/ws/actions';
import ReactGA from 'react-ga4';
import { REACT_APP_GA } from 'appConstants/ga';
import styles from './styles.module.scss';

const Analytics = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (REACT_APP_GA && location) {
      // eslint-disable-next-line no-console
      console.log('EASY APP GA LOGGED LOCATION', location.pathname);
      ReactGA.initialize(REACT_APP_GA);
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.send(location.pathname); // Record a pageview for the given page
    } else {
      // eslint-disable-next-line no-console
      console.error('No Google Analytics ID was found', location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(wsConnectAction());
  }, [dispatch]);

  return (
    <ToastProvider>
      <Web3Provider>
        <InitializeActionsProvider>
          <BrowserRouter>
            <Analytics />
            <div className={styles.container}>
              <Routes />
            </div>
          </BrowserRouter>
        </InitializeActionsProvider>
      </Web3Provider>
    </ToastProvider>
  );
};

export default App;
