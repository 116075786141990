import React from 'react';
import styles from './styles.module.scss';

function Footer() {
  return (
    <footer className={styles.container}>
      <div className={styles.topRow}>
        <ul className={styles.firstColumn}>
          <li className={styles.columnHeader}>XBE Finance</li>
          <li className={styles.longItem}>
            An Integrated decentralised finance protocol for earning with DeFi,
            FIAT and Tokenised security liquidity.
          </li>
        </ul>
        <span className={styles.mobileRow}>
          <ul className={styles.column}>
            <li className={styles.columnHeader}>About</li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://app.xbe.finance/', '_blank')}
            >
              App
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://xbe.finance/faq/', '_self')}
            >
              FAQs
            </li>
          </ul>
          <ul className={styles.column}>
            <li className={styles.columnHeader}> Resources</li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://xbe.finance/blog/', '_self')}
            >
              Blog
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://github.com/XBEfinance', '_blank')}
            >
              Github
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://github.com/XBEfinance/audits', '_blank')}
            >
              Audits
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://docs.xbe.finance/', '_blank')}
            >
              Docs
            </li>
          </ul>
          <ul className={styles.column}>
            <li className={styles.columnHeader}>Get In Touch</li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('mailto:contact@xbe.finance', '_self')}
            >
              contact@xbe.finance
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://twitter.com/XBEfinance', '_blank')}
            >
              Twitter
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://discord.com/invite/rRwwYnwtyu', '_blank')}
            >
              Discord
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open('https://t.me/xbefinance', '_blank')}
            >
              Telegram
            </li>
            <li
              role="none"
              className={styles.columnItem}
              onClick={() => window.open(
                'https://www.linkedin.com/company/xbe-finance/',
                '_blank',
              )}
            >
              LinkedIn
            </li>
          </ul>
        </span>
      </div>
      <div className={styles.border} />
      <div className={styles.bottomRow}>
        <div className={styles.bottomRowItem} style={{ textAlign: 'left' }}>
          {' Copyright @2021 XBE Finance & fiat.xbe.finance - Bitfair LLC. All Rights Reserved. '}
        </div>
        <div
          className={styles.bottomRowItem}
          style={{ textAlign: 'right' }}
          onClick={() => window.open('https://xbe.finance/terms-and-conditions/', '_self')}
        >
          Terms & Conditions
        </div>
      </div>
    </footer>
  );
}

export default Footer;
