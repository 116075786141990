/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import {
  provideLiquidityAddLiquidityOnlyEthAction,
  provideLiquidityGetTokenAllowanceAction,
  provideLiquidityGetPriceAction,
  provideLiquiditySetStatusAction,
  provideLiquidityGetTotalLiquidityAction,
} from 'store/provideLiquidity/actions';
import { XBE_ROUTER_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { xbeRouterAbi } from 'assets/abi';
import { selectAddress } from 'store/utils';
import { sushiSwapLPStakingGetBlockInfoAction } from 'store/sushiSwapLPStaking/actions';
import { meGetBalanceEthAction, meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import BigNumber from 'bignumber.js';

const DEADLINE_SECONDS = 3600;

function* provideLiquidityAddLiquidityOnlyEthSaga(
  { type, payload: amountEth }: ReturnType<typeof provideLiquidityAddLiquidityOnlyEthAction>,
) {
  try {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const deadline = String(Math.floor(Date.now() / 1000) + DEADLINE_SECONDS);

    const contract = getContract(xbeRouterAbi, XBE_ROUTER_CONTRACT_ADDRESS);
    const minSwapAmountXBE: string = yield contract
      .methods
      .getMinSwapAmountXBE(
        web3.utils.toWei(new BigNumber(amountEth).div(2).toFixed(18, 1)),
      )
      .call();

    const takeOutMin = new BigNumber(minSwapAmountXBE).multipliedBy(0.9).toFixed(0, 1);

    const params = [deadline, takeOutMin];
    const value = web3.utils.toWei(amountEth);

    const gasLimit: string = yield contract
      .methods
      .addLiquidity(...params)
      .estimateGas({
        from: address,
        gas: 1_000_000,
        value,
      });

    yield contract
      .methods
      .addLiquidity(...params)
      .send({
        from: address,
        gasLimit,
        value,
      });

    yield put(provideLiquidityGetPriceAction());
    yield put(provideLiquidityGetTokenAllowanceAction());
    yield put(provideLiquidityGetTotalLiquidityAction());
    yield put(sushiSwapLPStakingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.SUSHI_VAULT));
    yield put(meGetBalancesAction(BalancesToken.ETH_XBE));
    yield put(meGetBalanceEthAction());
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
    setNotification({ type: 'success', message: 'Deposit success' });
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location providerLiquidityAddliquidityOnlyEthSaga');
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.ADD_LIQUIDITY_ONLY_ETH,
    provideLiquidityAddLiquidityOnlyEthSaga,
  );
}
