/* eslint-disable no-console */
import { put, select, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import BigNumber from 'bignumber.js';
import { api } from 'utils';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { SushiSwapLPStakingActionTypes } from 'store/sushiSwapLPStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  sushiSwapLPStakingGetAllowanceAction,
  sushiSwapLPStakingGetBlockInfoAction,
  sushiSwapLPStakingSetStateAction,
  sushiSwapLPStakingSetStatusAction,
} from 'store/sushiSwapLPStaking/actions';
import { sushiVaultAbi } from 'assets/abi';
import {
  SUSHI_VAULT_CONTRACT_ADDRESS, XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { meSelector } from 'store/selectors';
import { MeState } from 'store/me/types';
import { selectAddress } from 'store/utils';

const SECONDS_IN_YEAR = 31536000;

function* sushiSwapLPStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof sushiSwapLPStakingGetBlockInfoAction>,
) {
  try {
    yield put(sushiSwapLPStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const contractSushiVault = getContract(sushiVaultAbi, SUSHI_VAULT_CONTRACT_ADDRESS);
    const address: string = yield selectAddress();

    const poolBalance: string = yield contractSushiVault
      .methods
      .balance()
      .call();

    const { sushiVault: sushiVaultBalance }: MeState['balances'] = yield select(meSelector.getProp('balances'));
    let percentageOfPool = '0';

    if (poolBalance && poolBalance !== '0') {
      percentageOfPool = new BigNumber(sushiVaultBalance)
        .div(web3.utils.fromWei(poolBalance))
        .multipliedBy(100)
        .toFixed(2);
    }

    const potentialXBEReturns: string = yield contractSushiVault
      .methods
      .potentialRewardReturns(
        XBE_CONTRACT_ADDRESS,
        SECONDS_IN_YEAR,
        address,
      )
      .call();

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=sushiVault`);

    yield put(sushiSwapLPStakingGetAllowanceAction());
    yield put(sushiSwapLPStakingSetStateAction({
      percentageOfPool,
      potentialXBEReturns,
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
    }));
    yield put(sushiSwapLPStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getBlockInfoSaga');
    yield put(sushiSwapLPStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    SushiSwapLPStakingActionTypes.GET_BLOCK_INFO,
    sushiSwapLPStakingGetBlockInfoSaga,
  );
}
