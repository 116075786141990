/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { put, takeEvery, select } from 'redux-saga/effects';
import { api } from 'utils';
import { XbeClaimActionTypes } from 'store/xbeClaim/actionTypes';
import { AxiosResponse } from 'axios';
import {
  xbeClaimGetBlockInfoAction,
  xbeClaimSetStateAction,
  xbeClaimSetStatusAction,
} from 'store/xbeClaim/actions';
import {
  getContract,
  RequestStatus,
  setNotification,
  formattersDate,
} from '@workstream/shared';
import { meSelector } from 'store/selectors';
import { votingStakingRewardsAbi, veXbeAbi } from 'assets/abi';
import { ResponseTokenClaim } from 'store/xbeClaim/types';
import {
  VOTING_STAKING_REWARDS_CONTRACT_ADDRESS,
  VE_XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { selectAddress } from 'store/utils';
import BigNumber from 'bignumber.js';

type BondedRewardLocksResult = {
  amount: string,
  unlockTime: string,
  requested: boolean,
};

const getToClaimWithoutPenalty = (dateNow: Date | number, dateUnlock: Date | number) => {
  let toClaimWithoutPenalty = '';

  const interval = formattersDate.intervalToDuration({
    start: dateNow,
    end: dateUnlock,
  });

  if (interval.days) toClaimWithoutPenalty += `${interval.days}d `;
  if (interval.hours) toClaimWithoutPenalty += `${interval.hours}h `;
  if (interval.minutes) toClaimWithoutPenalty += `${interval.minutes}m`;

  return toClaimWithoutPenalty;
};

function* xbeClaimGetBlockInfoSaga(
  { type }: ReturnType<typeof xbeClaimGetBlockInfoAction>,
) {
  try {
    yield put(xbeClaimSetStatusAction({ type, status: RequestStatus.REQUEST }));

    // This code is not being used in easy xbe, and its causing an error.
    // We are commenting it out for now until we find a solution.

    // const contract = getContract(votingStakingRewardsAbi,
    // VOTING_STAKING_REWARDS_CONTRACT_ADDRESS);
    // const contractVeXBE = getContract(veXbeAbi, VE_XBE_CONTRACT_ADDRESS);
    // const address: string = yield selectAddress();

    // const { amount, requested, unlockTime }: BondedRewardLocksResult = yield contract
    //   .methods
    //   .bondedRewardLocks(address)
    //   .call();

    // const balanceVotingStakingRewards: number = yield contract
    //   .methods
    //   .balanceOf(address)
    //   .call();
    // const lockedAmount: number = yield contractVeXBE
    //   .methods
    //   .lockedAmount(address)
    //   .call();

    // const dateNow = Date.now();
    // const dateUnlock = new Date(Number(`${unlockTime}000`)).getTime();

    // const toClaimWithoutPenalty = unlockTime !== '0' &&
    // new BigNumber(dateNow).isLessThan(dateUnlock) ?
    //   getToClaimWithoutPenalty(dateNow, dateUnlock) :
    //   '';

    // const xbeInUnbonding = new BigNumber(dateNow).isLessThan(dateUnlock) ?
    //   amount : '0';

    // const xbeAvailableToUnstake = new BigNumber(dateNow).isLessThan(dateUnlock) ?
    //   '0' : amount;

    const { data }: AxiosResponse<ResponseTokenClaim> = yield api.get(
      '/tokens/get-claim?address=0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16&token=XBE',
    );

    const boostLevel: string = yield select(meSelector.getProp('boostLevel'));

    const aprEndBN = new BigNumber(data.apr);
    const aprCurBN = new BigNumber(data.apr).multipliedBy(boostLevel);

    const aprStartBN = aprEndBN.div(2.5);

    yield put(xbeClaimSetStateAction({
      // toClaimWithoutPenalty,
      // amount: `${Math.min(
      //   +amount,
      //   new BigNumber(balanceVotingStakingRewards).minus(lockedAmount).toNumber(),
      // )}`,
      // unlockTime,
      // xbeInUnbonding,
      // xbeAvailableToUnstake,
      // isRequestUnbonding: requested,
      // earned: new BigNumber(data.earned).toFixed(2),
      // deposits: new BigNumber(data.deposits).toFixed(2),
      aprStart: aprStartBN.toFixed(2),
      apr: new BigNumber(data.apr).toFixed(2),
    }));
    yield put(xbeClaimSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location xbeclaim');
    yield put(xbeClaimSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    XbeClaimActionTypes.GET_BLOCK_INFO,
    xbeClaimGetBlockInfoSaga,
  );
}
