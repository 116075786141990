import {
  CVX_CRV_VAULT_CONTRACT_ADDRESS, CVX_VAULT_CONTRACT_ADDRESS,
  SUSHI_VAULT_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import {
  cvxCrvVaultAbi, cvxVaultAbi, sushiVaultAbi,
} from 'assets/abi';
import { Unit } from 'web3-utils';

export enum ClaimTokens {
  SUSHI = 'lp',
  CVX = 'CVX',
  CVX_CRV = 'CVX_CRV',
}

export const claimTokensAddress: Record<ClaimTokens, string> = {
  [ClaimTokens.SUSHI]: SUSHI_VAULT_CONTRACT_ADDRESS,
  [ClaimTokens.CVX]: CVX_VAULT_CONTRACT_ADDRESS,
  [ClaimTokens.CVX_CRV]: CVX_CRV_VAULT_CONTRACT_ADDRESS,
};

export const claimTokensAbi: Record<ClaimTokens, any> = {
  [ClaimTokens.SUSHI]: sushiVaultAbi,
  [ClaimTokens.CVX]: cvxVaultAbi,
  [ClaimTokens.CVX_CRV]: cvxCrvVaultAbi,
};

export const claimTokensDecimals: Record<ClaimTokens, Unit> = {
  [ClaimTokens.SUSHI]: 'ether',
  [ClaimTokens.CVX]: 'ether',
  [ClaimTokens.CVX_CRV]: 'ether',
};
