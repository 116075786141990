import React, {
  FC, useEffect, useCallback, useMemo,
} from 'react';
import cx from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BigNumber from 'bignumber.js';
import { useDispatch } from 'react-redux';
import { InputWithAvailable, NumberInSquare } from 'components';
import {
  Button, DefaultBlock, Text, web3, WriteContractStatus,
} from '@workstream/shared';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  icon: string,
  token: string,
  name: string,
  balance?: string,
  allowance?: string,
  stakingStatus?: string,
  onApprove: (value: string) => void,
  onStake: (value: string) => void,
  isShowTitle?: boolean,
  isShowDepositCurveButton?: boolean,
};

const DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

const NOTE = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

const initialValues = {
  deposit: '',
};

Yup.addMethod(Yup.string, 'deposit', function (
  errorMessage: string,
  maxDeposit: string,
  allowance: string,
) {
  return this.test('test-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.isLessThanOrEqualTo(maxDeposit),
        valueBN.isLessThanOrEqualTo(web3.utils.fromWei(allowance)),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

const Stake: FC<Props> = ({
  className,
  icon,
  token,
  name,
  balance = '',
  allowance = '',
  onApprove,
  onStake,
  stakingStatus,
  isShowTitle = true,
  isShowDepositCurveButton = true,
}) => {
  const dispatch = useDispatch();

  const {
    values,
    setValues,
    isValid,
    handleSubmit,
    validateField,
  } = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      deposit: Yup.string()
      // @ts-ignore
        .deposit('Error', balance, allowance)
        .required(),
    }),
    onSubmit: ({ deposit }) => {
      dispatch(onStake(deposit));
    },
  });

  useEffect(() => {
    validateField('deposit');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowance, validateField]);

  const onApproveClick = useCallback(() => {
    if (!values.deposit || values.deposit === '0') {
      onApprove('0');
    } else {
      onApprove(values.deposit);
    }
  }, [onApprove, values.deposit]);

  const handleChange = useCallback(
    (event) => setValues({ deposit: event.target.value }),
    [setValues],
  );

  const handleMaxClick = useCallback(() => setValues({ deposit: balance }), [balance, setValues]);

  const disabledApprove = useMemo(() => {
    const depositBN = new BigNumber(values.deposit);
    if (depositBN.isEqualTo(0)) return false;
    return [
      values.deposit.length === 0,
      depositBN.isLessThanOrEqualTo(allowance),
      stakingStatus === WriteContractStatus.APPROVING,
      stakingStatus === WriteContractStatus.WRITING,
    ].includes(true);
  }, [allowance, stakingStatus, values.deposit]);

  const disabledDeposit = useMemo(() => [
    !isValid,
    stakingStatus === WriteContractStatus.APPROVING,
    stakingStatus === WriteContractStatus.WRITING,
  ].includes(true), [isValid, stakingStatus]);

  return (
    <form
      onSubmit={handleSubmit}
      className={cx(styles.container, className)}
    >
      <Text
        className={styles.text}
        color="secondary"
      >
        {DESCRIPTION}
      </Text>

      <div className={styles.inner}>
        <div className={styles.left}>
          { isShowTitle && (
            <DefaultBlock
              theme="grey"
              className={styles.noteWrapper}
            >
              <Text
                className={styles.text}
                color="primary"
              >
                Title
              </Text>
              <Text
                className={styles.text}
              >
                {NOTE}
              </Text>
            </DefaultBlock>
          )}

          <InputWithAvailable
            className={styles.inputWithAvailable}
            classNameInput={styles.inputWithAvailableInput}
            icon={icon}
            name={`${name}-input`}
            placeholder="0.00"
            isShowAvailableLegend={false}
            available={(
              <span>
                <b>
                  {Number(balance).toFixed(2)} {token}
                </b>  tokens available
              </span>
            )}
            value={values.deposit}
            onChange={handleChange}
            onMaxClick={handleMaxClick}
          />
        </div>
        <div className={styles.right}>
          { isShowDepositCurveButton && (
            <Button
              className={cx(styles.button, styles.buttonDeposit)}
              isOutline
              size="sm"
            >
              Deposit into Curve
            </Button>
          )}

          <div className={styles.buttons}>
            <Button
              className={cx(styles.button, styles.buttonInRow)}
              isOutline
              size="sm"
              onClick={onApproveClick}
              disabled={disabledApprove}
            >
              <NumberInSquare className={styles.numberInSquare} number="1" />
              { stakingStatus === WriteContractStatus.APPROVING ? 'Authorize...' : 'Authorize' }
            </Button>
            <Button
              type="submit"
              className={cx(styles.button, styles.buttonInRow)}
              isOutline
              size="sm"
              disabled={disabledDeposit}
            >
              <NumberInSquare className={styles.numberInSquare} number="2" />
              { stakingStatus === WriteContractStatus.WRITING ? 'Writing...' : 'Deposit & Stake' }
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Stake;
