import createReducer from 'store/createReducer';
import { EurXbStakingState } from 'store/eurXbStaking/types';
import { EUR_XB_STAKING_HANDLERS } from 'store/eurXbStaking/handlers';
import { RequestStatus } from '@workstream/shared';
import { EurXbStakingActionTypes } from 'store/eurXbStaking/actionTypes';

const initialState: EurXbStakingState = {
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [EurXbStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, EUR_XB_STAKING_HANDLERS);
