import createReducer from 'store/createReducer';
import { LocationState } from 'store/location/types';
import { LOCATION_HANDLERS } from 'store/location/handlers';

const initialState: LocationState = {
  country: '',
  countryName: '',
  ui: {},
};

export default createReducer(initialState, LOCATION_HANDLERS);
