import { put, takeEvery, all } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  cvxCrvStakingGetAllowanceAction,
  cvxCrvStakingGetBlockInfoAction,
  cvxCrvStakingSetStateAction,
  cvxCrvStakingSetStatusAction,
} from 'store/cvxCrvStaking/actions';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { cvxCrvAbi, cvxCrvVaultAbi } from 'assets/abi';
import { CVX_CRV_CONTRACT_ADDRESS, CVX_CRV_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* cvxCrvStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof cvxCrvStakingGetBlockInfoAction>,
) {
  try {
    yield put(cvxCrvStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const contractCvxCrv = getContract(cvxCrvAbi, CVX_CRV_CONTRACT_ADDRESS);

    const totalSupply: string = yield contractCvxCrv
      .methods
      .totalSupply()
      .call();

    const address: string = yield selectAddress();

    const contract = getContract(cvxCrvVaultAbi, CVX_CRV_VAULT_CONTRACT_ADDRESS);

    const tokensCount: string = yield contract
      .methods
      .getRewardTokensCount()
      .call();

    const listRewardsContract: string[] = yield all(
      Array(Number(tokensCount)).fill(0).map((_, index) => contract
        .methods
        .getRewardToken(index)
        .call()),
    );

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=cvxCrvVault`);

    yield put(cvxCrvStakingSetStateAction({
      totalSupply: web3.utils.fromWei(totalSupply),
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
      listRewardsContract,
    }));
    yield put(cvxCrvStakingGetAllowanceAction());
    yield put(cvxCrvStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(cvxCrvStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    CvxCrvStakingActionTypes.GET_BLOCK_INFO,
    cvxCrvStakingGetBlockInfoSaga,
  );
}
