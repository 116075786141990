/* eslint-disable no-console */
import { all, put, takeLeading } from 'redux-saga/effects';
import {
  getContract, setNotification,
} from '@workstream/shared';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  stakeCurveLpGetRewardsContractsAddressesAction, stakeCurveLpSetRewardsContractsAddressesAction,
} from 'store/stakeCurveLp/actions';
import { ListRewardsContractsAddresses } from 'store/stakeCurveLp/types';
import {
  stakeCurveLpVaultTokensAbi,
  stakeCurveLpVaultTokensAddress,
} from 'store/stakeCurveLp/constants';
import { wethAbi } from 'assets/abi';

function* stakeCurveLpGetRewardsContractsAddressesSaga(
  { payload: token }: ReturnType<typeof stakeCurveLpGetRewardsContractsAddressesAction>,
) {
  try {
    const contract = getContract(
      stakeCurveLpVaultTokensAbi[token],
      stakeCurveLpVaultTokensAddress[token],
    );

    const { getRewardTokensCount, getRewardToken } = contract.methods;

    const tokensCount: string = yield getRewardTokensCount().call();

    const rewardsContractsPromise: string[] = [];
    for (let i = 0; i < Number(tokensCount); i += 1) {
      rewardsContractsPromise.push(getRewardToken(i).call());
    }
    const rewardsContracts: string[] = yield all(rewardsContractsPromise);

    const rewardsSymbolsPromise = rewardsContracts.map((addressReward) => {
      const { methods } = getContract(wethAbi, addressReward);
      return methods.symbol().call();
    });
    const rewardsSymbols: string[] = yield all(rewardsSymbolsPromise);

    const rewardsContractsSymbols: ListRewardsContractsAddresses =
    rewardsContracts.map((address, index) => ({
      address, symbol: rewardsSymbols[index],
    }));
    yield put(stakeCurveLpSetRewardsContractsAddressesAction(
      { token, list: rewardsContractsSymbols },
    ));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getRewardsContractsAddressesSaga');
  }
}

export function* getRewardsContractsAddressesSaga() {
  yield takeLeading(
    StakeCurveLpActionTypes.GET_REWARDS_CONTRACTS_ADDRESSES,
    stakeCurveLpGetRewardsContractsAddressesSaga,
  );
}
