import createReducer from 'store/createReducer';
import { RatesCurrencyToken, RateState, VenuesToken } from 'store/rate/types';
import { RATE_HANDLERS } from 'store/rate/handlers';

const initialState: RateState = {
  XBE: {
    [RatesCurrencyToken.USD]: {
      venue: VenuesToken.coinmarketcap,
      quote: '0',
      quantity: '0',
    },
  },
  ETH: {
    [RatesCurrencyToken.USD]: {
      venue: VenuesToken.coinmarketcap,
      quote: '0',
      quantity: '0',
    },
  },
  ui: {},
};

export default createReducer(initialState, RATE_HANDLERS);
