import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { EursStakingActionTypes } from 'store/eursStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  eursStakingSetStateAction,
  eursStakingSetStatusAction,
  eursStakingGetBlockInfoAction,
} from 'store/eursStaking/actions';
import {
  RequestStatus, setNotification,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';

function* eursStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof eursStakingGetBlockInfoAction>,
) {
  try {
    yield put(eursStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=eursVault`);

    yield put(eursStakingSetStateAction({
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
    }));
    yield put(eursStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(eursStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    EursStakingActionTypes.GET_BLOCK_INFO,
    eursStakingGetBlockInfoSaga,
  );
}
