/* eslint-disable no-console */
import { put, takeLeading } from 'redux-saga/effects';
import {
  getContract, setNotification,
} from '@workstream/shared';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  stakeCurveLpGetLpTokenAddressAction, stakeCurveLpSetLpTokenAddressAction,
} from 'store/stakeCurveLp/actions';
import {
  stakeCurveLpVaultTokensAbi,
  stakeCurveLpVaultTokensAddress,
} from 'store/stakeCurveLp/constants';

function* stakeCurveLpGetLpTokenAddressSaga(
  { payload: token }: ReturnType<typeof stakeCurveLpGetLpTokenAddressAction>,
) {
  try {
    const contract = getContract(
      stakeCurveLpVaultTokensAbi[token],
      stakeCurveLpVaultTokensAddress[token],
    );
    const lpTokenAddress: string = yield contract.methods.stakingToken().call();

    yield put(stakeCurveLpSetLpTokenAddressAction({ token, address: lpTokenAddress }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getLpTokenAddressSaga');
  }
}

export function* getLpTokenAddressSaga() {
  yield takeLeading(
    StakeCurveLpActionTypes.GET_LP_TOKEN_ADDRESS,
    stakeCurveLpGetLpTokenAddressSaga,
  );
}
