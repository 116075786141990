import { put, takeEvery } from 'redux-saga/effects';
import {
  RequestStatus,
  setNotification,
} from '@workstream/shared';
import {
  meSetIsSignedAction,
  meSetStatusAction,
  meSetStateAction,
} from 'store/me/actions';
import { MeActionTypes } from 'store/me/actionTypes';
import { selectAddress } from 'store/utils';
import firebase from 'utils/firebase';
import {
  getDatabase, ref, set, get, child,
} from 'firebase/database';

const db = getDatabase(firebase);

function* signSaga({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  payload: { userSignedData }, type,
}: ReturnType<typeof meSetIsSignedAction>) {
  try {
    yield put(meSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    let isSignedValue = false;
    const dbRef = ref(getDatabase());

    yield get(child(dbRef, `users/${address}`))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          set(ref(db, `users/${address}`), {
            userWalletAddress: address,
            payload: userSignedData,
          });
        }

        isSignedValue = true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });

    yield put(
      meSetStateAction({
        isSigned: isSignedValue,
      }),
    );

    yield put(meSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(meSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* meSignSaga() {
  yield takeEvery(MeActionTypes.SET_SIGNED, signSaga);
}
