import { SetStatusPayload } from 'store/types';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import { LockupInfoState } from 'store/lockupInfo/types';
import { LiquidityPool } from 'appConstants';

export const lockupInfoSetStateAction = (payload: Partial<LockupInfoState>) => ({
  type: LockupInfoActionTypes.SET_STATE,
  payload,
});

export const lockupInfoSetStatusAction = (
  payload: SetStatusPayload<LockupInfoActionTypes>,
) => ({
  type: LockupInfoActionTypes.SET_STATUS,
  payload,
});

export const lockupInfoGetBlockInfoAction = () => ({
  type: LockupInfoActionTypes.GET_BLOCK_INFO,
});

export const infoGetPriceAction = () => ({
  type: LockupInfoActionTypes.GET_PRICE,
});

export const infoSetPriceAction = (payload: Record<LiquidityPool, string>) => ({
  type: LockupInfoActionTypes.GET_PRICE_SUCCESS,
  payload,
});

export const infoSetReversePriceAction = (payload: Record<LiquidityPool, string>) => ({
  type: LockupInfoActionTypes.SET_REVERSE_PRICE,
  payload,
});
