import { CurveLpToken } from 'store/stakeCurveLp/constants';
import {
  IconCurveLpEurs, IconCurveLpIronbank, IconCurveLpStEth, IconCurveLpUsdn,
} from 'assets/img';

export const STAKE_ICON_INPUT: Record<CurveLpToken, string> = {
  [CurveLpToken.USDN]: IconCurveLpUsdn,
  [CurveLpToken.IRON_BANK]: IconCurveLpIronbank,
  [CurveLpToken.ST_ETH]: IconCurveLpStEth,
  [CurveLpToken.EURS]: IconCurveLpEurs,
};

export const UNSTAKE_ICON_INPUT: Record<CurveLpToken, string> = {
  [CurveLpToken.USDN]: IconCurveLpUsdn,
  [CurveLpToken.IRON_BANK]: IconCurveLpIronbank,
  [CurveLpToken.ST_ETH]: IconCurveLpStEth,
  [CurveLpToken.EURS]: IconCurveLpEurs,
};

export const STAKE_TOKEN_NAME: Record<CurveLpToken, string> = {
  [CurveLpToken.USDN]: 'cCrv',
  [CurveLpToken.IRON_BANK]: 'cCrv',
  [CurveLpToken.ST_ETH]: 'cCrv',
  [CurveLpToken.EURS]: 'cCrv',
};

export const UNSTAKE_TOKEN_NAME: Record<CurveLpToken, string> = {
  [CurveLpToken.USDN]: 'cCrv',
  [CurveLpToken.IRON_BANK]: 'cCrv',
  [CurveLpToken.ST_ETH]: 'cCrv',
  [CurveLpToken.EURS]: 'cCrv',
};

export const STAKE_TOKEN_DESCRIPTION: Record<CurveLpToken, string> = {
  [CurveLpToken.USDN]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.IRON_BANK]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.ST_ETH]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.EURS]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
};

export const STAKE_TOKEN_NOTE: Record<CurveLpToken, string> = {
  [CurveLpToken.USDN]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.IRON_BANK]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.ST_ETH]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.EURS]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
};

export const UNSTAKE_TOKEN_DESCRIPTION: Record<CurveLpToken, string> = {
  [CurveLpToken.USDN]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.IRON_BANK]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.ST_ETH]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  [CurveLpToken.EURS]: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
};
