/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { Contract } from 'web3-eth-contract';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { XbeStakingActionTypes } from 'store/xbeStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  xbeStakingGetAllowanceAction,
  xbeStakingGetBlockInfoAction,
  xbeStakingSetStateAction,
  xbeStakingSetStatusAction,
} from 'store/xbeStaking/actions';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { treasuryAbi, veXbeAbi, votingStakingRewardsAbi } from 'assets/abi';
import { TREASURY_CONTRACT_ADDRESS, VE_XBE_CONTRACT_ADDRESS, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

type BondedRewardLocksResult = {
  amount: string,
  requested: boolean,
  unlockTime: string,
};

type LockedResult = {
  amount: string,
  end: string,
};

function* getBoostLevel(contract: Contract, address: string) {
  const calculateBoostLevel: string = yield contract
    .methods
    .calculateBoostLevel(address)
    .call();

  const maxBoostCoefficient: string = yield contract
    .methods
    .inverseMaxBoostCoefficient()
    .call();

  return new BigNumber(web3.utils.fromWei(calculateBoostLevel))
    .multipliedBy(100)
    .div(maxBoostCoefficient)
    .toFixed(1);
}

function* xbeStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof xbeStakingGetBlockInfoAction>,
) {
  try {
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const contractVoting =
      getContract(votingStakingRewardsAbi, VOTING_STAKING_REWARDS_CONTRACT_ADDRESS);

    const totalSupply: string = yield contractVoting
      .methods
      .totalSupply()
      .call();

    const address: string = yield selectAddress();

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=votingStakingRewards`);

    yield put(xbeStakingSetStateAction({
      totalSupply: web3.utils.fromWei(totalSupply),
      bondedAndLockedXbe: undefined,
      boostLevel: undefined,
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
    }));
    yield put(xbeStakingGetAllowanceAction());
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location xbestaking');
    yield put(xbeStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    XbeStakingActionTypes.GET_BLOCK_INFO,
    xbeStakingGetBlockInfoSaga,
  );
}
