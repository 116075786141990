import createReducer from 'store/createReducer';
import { RequestStatus } from '@workstream/shared';
import { XbeClaimState } from 'store/xbeClaim/types';
import { XBE_CLAIM_HANDLERS } from 'store/xbeClaim/handlers';
import { XbeClaimActionTypes } from 'store/xbeClaim/actionTypes';

const initialState: XbeClaimState = {
  amount: '0',
  unlockTime: '0',
  toClaimWithoutPenalty: '',
  xbeAvailableToUnstake: '0',
  xbeInUnbonding: '0',
  isRequestUnbonding: false,
  earned: '0',
  apr: '0',
  aprStart: '0',
  deposits: '0',
  ui: {
    [XbeClaimActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
    [XbeClaimActionTypes.CLAIM]: RequestStatus.INIT,
    [XbeClaimActionTypes.UNSTAKE]: RequestStatus.INIT,
    [XbeClaimActionTypes.UNBOND]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, XBE_CLAIM_HANDLERS);
