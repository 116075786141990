import { HandlerFn } from 'store/types';
import { StakeCurveLpActionTypes } from './actionTypes';
import { StakeCurveLpState } from './types';
import {
  stakeCurveLpSetAllowanceAction,
  stakeCurveLpSetBalanceAction,
  stakeCurveLpSetBalanceVaultAction, stakeCurveLpSetLpTokenAddressAction,
  stakeCurveLpSetRewardsContractsAddressesAction,
  stakeCurveLpSetStateAction,
  stakeCurveLpSetStatusAction,
  stakeCurveLpSetSubmitStatusAction,
  stakeCurveLpSetWithdrawStatusAction,
} from './actions';

// eslint-disable-next-line max-len
type StakeCurveLpActionFn<F extends (...args: any) => any> = HandlerFn<StakeCurveLpState, ReturnType<F>>;

const setState: StakeCurveLpActionFn<typeof stakeCurveLpSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: StakeCurveLpActionFn<typeof stakeCurveLpSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

const setBalance: StakeCurveLpActionFn<typeof stakeCurveLpSetBalanceAction> = (
  state,
  { payload: { token, balance } },
) => ({
  ...state,
  balances: {
    ...state.balances,
    [token]: balance,
  },
});

const setBalanceVault: StakeCurveLpActionFn<typeof stakeCurveLpSetBalanceVaultAction> = (
  state,
  { payload: { token, balance } },
) => ({
  ...state,
  balancesVaults: {
    ...state.balancesVaults,
    [token]: balance,
  },
});

const setAllowance: StakeCurveLpActionFn<typeof stakeCurveLpSetAllowanceAction> = (
  state,
  { payload: { token, allowance } },
) => ({
  ...state,
  allowances: {
    ...state.allowances,
    [token]: allowance,
  },
});

const setSubmitStatus: StakeCurveLpActionFn<typeof stakeCurveLpSetSubmitStatusAction> = (
  state, { payload },
) => ({
  ...state,
  uiSubmitStatus: {
    ...state.uiSubmitStatus,
    ...payload,
  },
});

const setWithdrawStatus: StakeCurveLpActionFn<typeof stakeCurveLpSetWithdrawStatusAction> = (
  state, { payload },
) => ({
  ...state,
  uiWithdrawStatus: {
    ...state.uiWithdrawStatus,
    ...payload,
  },
});

const setRewardsContractsAddresses:
StakeCurveLpActionFn<typeof stakeCurveLpSetRewardsContractsAddressesAction> = (
  state, { payload: { token, list } },
) => ({
  ...state,
  listRewardsContractsAddresses: {
    ...state.listRewardsContractsAddresses,
    [token]: list,
  },
});

const setLpTokenAddresses:
StakeCurveLpActionFn<typeof stakeCurveLpSetLpTokenAddressAction> = (
  state, { payload: { token, address } },
) => ({
  ...state,
  lpTokensAddresses: {
    ...state.lpTokensAddresses,
    [token]: address,
  },
});

export const STAKE_CURVE_LP_HANDLERS = {
  [StakeCurveLpActionTypes.SET_STATE]: setState,
  [StakeCurveLpActionTypes.SET_STATUS]: setStatus,
  [StakeCurveLpActionTypes.SET_BALANCE]: setBalance,
  [StakeCurveLpActionTypes.SET_BALANCE_VAULT]: setBalanceVault,
  [StakeCurveLpActionTypes.SET_ALLOWANCE]: setAllowance,
  [StakeCurveLpActionTypes.SET_SUBMIT_STATUS]: setSubmitStatus,
  [StakeCurveLpActionTypes.SET_WITHDRAW_STATUS]: setWithdrawStatus,
  [StakeCurveLpActionTypes.SET_REWARDS_CONTRACTS_ADDRESSES]: setRewardsContractsAddresses,
  [StakeCurveLpActionTypes.SET_LP_TOKEN_ADDRESS]: setLpTokenAddresses,
};
