/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { StEthStakingActionTypes } from 'store/stEthStaking/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  stEthStakingSetStateAction,
  stEthStakingSetStatusAction,
  stEthStakingGetBlockInfoAction,
} from 'store/stEthStaking/actions';
import {
  RequestStatus, setNotification,
} from '@workstream/shared';
import { selectAddress } from 'store/utils';

function* stEthStakingGetBlockInfoSaga(
  { type }: ReturnType<typeof stEthStakingGetBlockInfoAction>,
) {
  try {
    yield put(stEthStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));

    const address: string = yield selectAddress();

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=stEthVault`);

    yield put(stEthStakingSetStateAction({
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
    }));
    yield put(stEthStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getBlockInfoSaga');
    yield put(stEthStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    StEthStakingActionTypes.GET_BLOCK_INFO,
    stEthStakingGetBlockInfoSaga,
  );
}
