/* eslint-disable no-console */
import { takeLeading, put } from 'redux-saga/effects';
import { RequestStatus, setNotification } from '@workstream/shared';
import {
  referralsMeIncReferralHitAction,
  referralsMeSetStatusAction,
} from 'store/referrals/me/actions';
import { ReferralsMeActionTypes } from 'store/referrals/me/actionTypes';
import { api } from 'utils';

function* referralsMeIncReferralHitSaga(
  { type, payload: referralCode }: ReturnType<typeof referralsMeIncReferralHitAction>,
) {
  try {
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.REQUEST }));

    yield api.get(`/users/user/referral-hit?referralCode=${referralCode}`);

    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    if (!e.response || (e.response && e.response.status !== 404)) {
      setNotification({ type: 'error', message: e.message });
      console.log('error', e.message);
      console.log('error location incReferralHitSaga');
    }
    yield put(referralsMeSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* incReferralHitSaga() {
  yield takeLeading(
    ReferralsMeActionTypes.INC_REFERRAL_HIT,
    referralsMeIncReferralHitSaga,
  );
}
