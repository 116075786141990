import createReducer from 'store/createReducer';
import { LockupInfoState } from 'store/lockupInfo/types';
import { LOCKUP_INFO_HANDLERS } from 'store/lockupInfo/handlers';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import { RequestStatus } from '@workstream/shared';

const initialState: LockupInfoState = {
  dailyApy: '0',
  apyPercentage: '0',
  currentCirculatingSupply: '0',
  totalLockedXBE: '0',
  percentageTotalLockedXBE: '0',
  maxSupply: '0',
  treasury: '0',
  xbeContractBalance: '0',
  totalStake: '0',
  ui: {
    [LockupInfoActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
  price: {
    ETH_USDT: '0',
    ETH_XBE: '0',
    XBE_USDT: '0',
  },
  reversePrice: {
    ETH_USDT: '0',
    ETH_XBE: '0',
    XBE_USDT: '0',
  },
};

export default createReducer(initialState, LOCKUP_INFO_HANDLERS);
