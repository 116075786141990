import web3 from './web3/reducer';
import provideLiquidity from './provideLiquidity/reducer';
import me from './me/reducer';
import sushiSwapLPStaking from './sushiSwapLPStaking/reducer';
import lockupInfo from './lockupInfo/reducer';
import minter from './minter/reducer';
import boost from './boost/reducer';
import xbeLockup from './xbeLockup/reducer';
import xbeStaking from './xbeStaking/reducer';
import rate from './rate/reducer';
import claim from './claim/reducer';
import xbeClaim from './xbeClaim/reducer';
import referralsMe from './referrals/me/reducer';
import referralsInfo from './referrals/info/reducer';
import cvxStaking from './cvxStaking/reducer';
import cvxCrvStaking from './cvxCrvStaking/reducer';
import crvConverting from './crvConverting/reducer';
import statistics from './statistics/reducer';
import ironBankStaking from './ironBankStaking/reducer';
import eursStaking from './eursStaking/reducer';
import eurXbStaking from './eurXbStaking/reducer';
import stEthStaking from './stEthStaking/reducer';
import usdnStaking from './usdnStaking/reducer';
import stakeCurveLp from './stakeCurveLp/reducer';
import location from './location/reducer';
import eurtStaking from './eurtStaking/reducer';

export default {
  web3,
  provideLiquidity,
  me,
  sushiSwapLPStaking,
  lockupInfo,
  minter,
  boost,
  xbeLockup,
  rate,
  claim,
  xbeStaking,
  xbeClaim,
  referralsMe,
  referralsInfo,
  cvxStaking,
  cvxCrvStaking,
  crvConverting,
  statistics,
  ironBankStaking,
  eursStaking,
  eurXbStaking,
  stEthStaking,
  usdnStaking,
  stakeCurveLp,
  location,
  eurtStaking,
};
