import { HandlerFn } from 'store/types';
import { LockupInfoActionTypes } from 'store/lockupInfo/actionTypes';
import { LockupInfoState } from 'store/lockupInfo/types';
import {
  infoSetPriceAction, infoSetReversePriceAction,
  lockupInfoSetStateAction, lockupInfoSetStatusAction,
} from 'store/lockupInfo/actions';

type LockupInfoActionFn<F extends (...args: any) => any> =
  HandlerFn<LockupInfoState, ReturnType<F>>;

const setState: LockupInfoActionFn<typeof lockupInfoSetStateAction> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: LockupInfoActionFn<typeof lockupInfoSetStatusAction> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

const setPrice: LockupInfoActionFn<typeof infoSetPriceAction> = (
  state,
  { payload },
) => ({
  ...state,
  price: payload,
});

export const setReversePrice: LockupInfoActionFn<typeof infoSetReversePriceAction> = (
  state,
  { payload },
) => ({
  ...state,
  reversePrice: payload,
});

export const LOCKUP_INFO_HANDLERS = {
  [LockupInfoActionTypes.SET_STATE]: setState,
  [LockupInfoActionTypes.SET_STATUS]: setStatus,
  [LockupInfoActionTypes.GET_PRICE_SUCCESS]: setPrice,
  [LockupInfoActionTypes.SET_REVERSE_PRICE]: setReversePrice,

};
