/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import cx from 'classnames';
import { web3Selector, useShallowSelector, meSelector } from 'store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  Text, H1, Button, web3, formatters,
} from '@workstream/shared';
import XBFBlock from 'components/XBFBlock';
import { RequirementWalletProvider } from 'containers';
import detectEthereumProvider from '@metamask/detect-provider';
import {
  meGetIsSignedAction,
  meGetXBFRewardsAction,
  meSetIsSignedAction,
} from 'store/me/actions';
import BigNumber from 'bignumber.js';
import SUSHI from 'assets/img/sushiswap1.svg';
import XBE from 'assets/img/coins/XBE.svg';
import reward from 'assets/img/reward.svg';
import rewardEarned from 'assets/img/rewardEarned.svg';
import etherscan from 'assets/img/etherscan.svg';
import styles from './styles.module.scss';

const MS_TO_DETECT_METAMASK = 10;

function RewardsBlocks() {
  const dispatch = useDispatch();
  const metamaskAddress = useSelector(web3Selector.getProp('address'));
  const dialog = React.useRef(null);
  const dialogContent = React.useRef(null);
  const isSigned = useShallowSelector(meSelector.getProp('isSigned'));
  const xbfRewards = useShallowSelector(meSelector.getProp('xbfRewards'));

  // isSigned = true;

  React.useEffect(() => {
    if (metamaskAddress) {
      dispatch(meGetIsSignedAction());
    }

    if (isSigned && metamaskAddress) {
      dispatch(meGetXBFRewardsAction());
    }
  }, [metamaskAddress, isSigned]);

  const msgParams =
             `Use of the xbe.finance website, services, dapp, or application
              is subject to the associated terms and conditions, and I hereby confirm
              that I am aware of these and accept them in full. Over and above
              the referred to terms and conditions, I specifically confirm and
              accept the following:
              1) xbe.finance is a smart contract protocol in alpha stage of launch,
                 and even though multiple security audits have been completed on the smart
                 contracts, I understand the risks associated with using the xbe.finance
                 protocol and associated functions.
              2) Any interactions that I have with the associated XBE protocol apps,
                 smart contracts or any related functions MAY place my funds at risk, and
                 I hereby release the XBE protocol and its contributors, team members,
                 service providers from any and all liability associated with my use of the
                 functions.
              3) I am lawfully permitted to access this site and use the xbe.finance
                 application functions, and I am not in contravention of any laws
                 governing my jurisdiction of residence or citizenship.`;

  const handleOpenDialog = () => {
    if (dialog.current) {
      // @ts-ignore
      dialog.current.style.display = 'block';
    }
  };

  const handleCloseDialog = () => {
    if (dialog.current) {
      // @ts-ignore
      dialog.current.style.display = 'none';
    }
  };

  const handleClick = async () => {
    try {
      const provider: any = await detectEthereumProvider({
        timeout: MS_TO_DETECT_METAMASK,
      });

      if (provider) {
        handleCloseDialog();
        const sign = await provider.request({
          method: 'personal_sign',
          params: [msgParams, metamaskAddress],
        });

        if (sign) {
          dispatch(meSetIsSignedAction({ userSignedData: sign }));

          setTimeout(() => {
            dispatch(meGetIsSignedAction());
          }, 1000);
        }
      }
    } catch (err) {
    // eslint-disable-next-line no-console
      console.error(err);
      handleOpenDialog();
    }
  };

  React.useEffect(() => {
    if (isSigned === false) {
      handleOpenDialog();
    }
  }, [isSigned]);

  const calculatePercentage = (total: string, value: string) => {
    const value1 = new BigNumber(value);
    const total1 = new BigNumber(total);

    const percentage = value1.div(total1).multipliedBy(100);

    // eslint-disable-next-line no-nested-ternary
    return !percentage.isFinite()
      ? '0'
      : percentage.isGreaterThan(0)
        ? percentage.toFixed(2)
        : '0';
  };

  const calcTotal = (value1: string, value2: string) => {
    const value11 = new BigNumber(value1);
    const value22 = new BigNumber(value2);

    const total = value11.plus(value22);

    return total.toFixed(4);
  };

  return (
    <>
      <div className={styles.container}>
        <RequirementWalletProvider>
          <div className={styles.blocks}>
            <XBFBlock
              className={cx(styles.totalEarned, styles.earnedContainer)}
              icon={rewardEarned}
              text="Your Total XBF Earned (veXBE & Reward Periods)"
              value={`${calcTotal(
                web3.utils.fromWei(xbfRewards.snapshotReward),
                web3.utils.fromWei(xbfRewards.userTotalXBFEarned),
              )} XBF`}
              textClassName={styles.earnedText}
              valueClassName={styles.earnedValue}
            />
            <XBFBlock
              className={cx(styles.rewardsPeriod, styles.earnedContainer)}
              icon={reward}
              text="Total XBF Awarded to All Users"
              value="10,478 XBF"
              textClassName={styles.earnedText}
              valueClassName={styles.rewardsValue}
            />
          </div>
          <div className={cx(styles.blocks2, styles.blocksStyles)}>
            <div className={styles.blocks}>
              <XBFBlock
                className={cx(
                  styles.totalEarned,
                  styles.earnedContainer,
                  styles.noBorder,
                )}
                // icon={rewardEarned}
                text="Your Total XBF Earned at veXBE Snapshot"
                value={`${formatters.toFormatViaBN(
                  web3.utils.fromWei(xbfRewards.snapshotReward),
                  4,
                )} XBF`}
                textClassName={styles.earnedText}
                valueClassName={styles.earnedValue}
              />
              <XBFBlock
                className={cx(
                  styles.rewardsPeriod,
                  styles.earnedContainer,
                  styles.noBorder,
                )}
                // icon={reward}
                text="Total XBF Rewarded at Snapshot"
                value="7,500 XBF"
                textClassName={styles.earnedText}
                valueClassName={styles.rewardsValue}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.dateBlock1}>
                <H1 className={styles.veXbe}>veXBE SNAPSHOT</H1>
                <Text className={styles.start}>Snapshot Date</Text>
                <H1 className={styles.date}>27 Dec 2021</H1>
                <Text className={styles.text}>Snashot Block</Text>
                <Text className={styles.blockValue}>13887500</Text>
                <button type="button" className={styles.outlinedButton}>
                  <img
                    className={styles.ethericon}
                    src={etherscan}
                    alt="etherscan"
                  />
                  <a
                    href="https://etherscan.io/block/13887500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Etherscan
                  </a>
                </button>
              </div>
              <div className={styles.periodBlocks1}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Amount of Total Rewards Earned
                </div>
                <div className={styles.periodBlock3}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="Your XBF Earned From veXBE"
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.snapshotReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock4}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="Your percentage (%) Earned From All veXBE"
                    value={`${calculatePercentage(
                      '7500000000000000000000',
                      xbfRewards.snapshotReward,
                    )}%`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.blocks}>
            <XBFBlock
              className={cx(styles.totalEarned, styles.earnedContainer)}
              icon={rewardEarned}
              text="Your Total XBF Earned"
              value={`${formatters.toFormatViaBN(
                web3.utils.fromWei(xbfRewards.userTotalXBFEarned),
                4,
              )} XBF`}
              textClassName={styles.earnedText}
              valueClassName={styles.earnedValue}
            />
            <XBFBlock
              className={cx(styles.rewardsPeriod, styles.earnedContainer)}
              icon={reward}
              text="Total XBF for Reward Periods 1 - 6"
              value="2,978 XBF"
              textClassName={styles.earnedText}
              valueClassName={styles.rewardsValue}
            />
          </div>
          <div className={cx(styles.blocks, styles.blocksStyles)}>
            <div className={styles.block}>
              <div className={styles.blockRow}>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Start Date</Text>
                  <H1 className={styles.date}>17 Nov 2021</H1>
                  <Text className={styles.text}>Start Block</Text>
                  <Text className={styles.blockValue}>13634705</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13634705"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>End Date</Text>
                  <H1 className={styles.date}>25 Nov 2021</H1>
                  <Text className={styles.text}>End Block</Text>
                  <Text className={styles.blockValue}>13685193</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13685193"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Reward Number</Text>
                  <H1 className={styles.date}>REWARD 6</H1>
                  <Text className={styles.text}>Reward Blocks</Text>
                  <Text className={styles.blockValue}>50488</Text>
                </div>
              </div>
              <div className={styles.periodBlocks}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Period XBF
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value="0 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value="360 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
            <div className={cx(styles.block, styles.periodBorder)}>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Amount of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period6.userStakingReward)
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period6.userStakingReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period6.userSushiReward)
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period6.userSushiReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Percentage (%) of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value={`${calculatePercentage(
                      xbfRewards.period6.stakingReward,
                      xbfRewards.period6.userStakingReward,
                    )}%`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value={`${calculatePercentage(
                      xbfRewards.period6.sushiReward,
                      xbfRewards.period6.userSushiReward,
                    )}% XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx(
              styles.blocks,
              styles.blocksStyles,
              styles.periodBackground,
            )}
          >
            <div className={styles.block}>
              <div className={styles.blockRow}>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Start Date</Text>
                  <H1 className={styles.date}>10 Nov 2021</H1>
                  <Text className={styles.text}>Start Block</Text>
                  <Text className={styles.blockValue}>13590232</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13590232"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>End Date</Text>
                  <H1 className={styles.date}>17 Nov 2021</H1>
                  <Text className={styles.text}>End Block</Text>
                  <Text className={styles.blockValue}>13634704</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13634704"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Reward Number</Text>
                  <H1 className={styles.date}>REWARD 5</H1>
                  <Text className={styles.text}>Reward Blocks</Text>
                  <Text className={styles.blockValue}>44472</Text>
                </div>
              </div>
              <div className={styles.periodBlocks}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Period XBF
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value="180 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value="180 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
            <div className={cx(styles.block, styles.periodBorder)}>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Amount of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period5.userStakingReward),
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period5.userStakingReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period5.userSushiReward)
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period5.userSushiReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Percentage (%) of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value={`${calculatePercentage(
                      xbfRewards.period5.stakingReward,
                      xbfRewards.period5.userStakingReward,
                    )}%`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value={`${calculatePercentage(
                      xbfRewards.period5.sushiReward,
                      xbfRewards.period5.userSushiReward,
                    )}% XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </RequirementWalletProvider>
        <RequirementWalletProvider>
          <div className={cx(styles.blocks, styles.blocksStyles)}>
            <div className={styles.block}>
              <div className={styles.blockRow}>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Start Date</Text>
                  <H1 className={styles.date}>03 Nov 2021</H1>
                  <Text className={styles.text}>Start Block</Text>
                  <Text className={styles.blockValue}>13545642</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13545642"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>End Date</Text>
                  <H1 className={styles.date}>10 Nov 2021</H1>
                  <Text className={styles.text}>End Block</Text>
                  <Text className={styles.blockValue}>13590231</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13590231"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Reward Number</Text>
                  <H1 className={styles.date}>REWARD 4</H1>
                  <Text className={styles.text}>Reward Blocks</Text>
                  <Text className={styles.blockValue}>44589</Text>
                </div>
              </div>
              <div className={styles.periodBlocks}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Period XBF
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value="360 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value="360 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
            <div className={cx(styles.block, styles.periodBorder)}>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Amount of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period4.userStakingReward)
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period4.userStakingReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period4.userSushiReward),
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period4.userSushiReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Percentage (%) of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value={`${calculatePercentage(
                      xbfRewards.period4.stakingReward,
                      xbfRewards.period4.userStakingReward,
                    )}%`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value={`${calculatePercentage(
                      xbfRewards.period4.sushiReward,
                      xbfRewards.period4.userSushiReward,
                    )}% XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx(
              styles.blocks,
              styles.blocksStyles,
              styles.periodBackground,
            )}
          >
            <div className={styles.block}>
              <div className={styles.blockRow}>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Start Date</Text>
                  <H1 className={styles.date}>27 Oct 2021</H1>
                  <Text className={styles.text}>Start Block</Text>
                  <Text className={styles.blockValue}>13501115</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13501115"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>End Date</Text>
                  <H1 className={styles.date}>03 Nov 2021</H1>
                  <Text className={styles.text}>End Block</Text>
                  <Text className={styles.blockValue}>13545641</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13545641"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Reward Number</Text>
                  <H1 className={styles.date}>REWARD 3</H1>
                  <Text className={styles.text}>Reward Blocks</Text>
                  <Text className={styles.blockValue}>44526</Text>
                </div>
              </div>
              <div className={styles.periodBlocks}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Period XBF
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value="360 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value="360 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
            <div className={cx(styles.block, styles.periodBorder)}>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Amount of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period3.userStakingReward),
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period3.userStakingReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period3.userSushiReward),
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period3.userSushiReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Percentage (%) of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value={`${calculatePercentage(
                      xbfRewards.period3.stakingReward,
                      xbfRewards.period3.userStakingReward,
                    )}%`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value={`${calculatePercentage(
                      xbfRewards.period3.sushiReward,
                      xbfRewards.period3.userSushiReward,
                    )}% XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={cx(styles.blocks, styles.blocksStyles)}>
            <div className={styles.block}>
              <div className={styles.blockRow}>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Start Date</Text>
                  <H1 className={styles.date}>20 Oct 2021</H1>
                  <Text className={styles.text}>Start Block</Text>
                  <Text className={styles.blockValue}>13456361</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13456361"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>End Date</Text>
                  <H1 className={styles.date}>27 Oct 2021</H1>
                  <Text className={styles.text}>End Block</Text>
                  <Text className={styles.blockValue}>13501114</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13501114"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Reward Number</Text>
                  <H1 className={styles.date}>REWARD 2</H1>
                  <Text className={styles.text}>Reward Blocks</Text>
                  <Text className={styles.blockValue}>44753</Text>
                </div>
              </div>
              <div className={styles.periodBlocks}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Period XBF
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value="0 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value="720 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
            <div className={cx(styles.block, styles.periodBorder)}>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Amount of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period2.userStakingReward),
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period2.userStakingReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period2.userSushiReward)
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period2.userSushiReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Percentage (%) of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value={`${calculatePercentage(
                      xbfRewards.period2.stakingReward,
                      xbfRewards.period2.userStakingReward,
                    )}%`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value={`${calculatePercentage(
                      xbfRewards.period2.sushiReward,
                      xbfRewards.period2.userSushiReward,
                    )}% XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx(
              styles.blocks,
              styles.blocksStyles,
              styles.periodBackground,
            )}
          >
            <div className={styles.block}>
              <div className={styles.blockRow}>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Start Date</Text>
                  <H1 className={styles.date}>11 Oct 2021</H1>
                  <Text className={styles.text}>Start Block</Text>
                  <Text className={styles.blockValue}>13399026</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13399026"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>End Date</Text>
                  <H1 className={styles.date}>20 Oct 2021</H1>
                  <Text className={styles.text}>End Block</Text>
                  <Text className={styles.blockValue}>13456360</Text>
                  <button type="button" className={styles.outlinedButton}>
                    <img
                      className={styles.ethericon}
                      src={etherscan}
                      alt="etherscan"
                    />

                    <a
                      href="https://etherscan.io/block/13456360"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Etherscan
                    </a>
                  </button>
                </div>
                <div className={styles.dateBlock}>
                  <Text className={styles.start}>Reward Number</Text>
                  <H1 className={styles.date}>REWARD 1</H1>
                  <Text className={styles.text}>Reward Blocks</Text>
                  <Text className={styles.blockValue}>57334</Text>
                </div>
              </div>
              <div className={styles.periodBlocks}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Period XBF
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value="90 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value="270 XBF"
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfsmallValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
            <div className={cx(styles.block, styles.periodBorder)}>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Amount of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period1.userStakingReward),
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period1.userStakingReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    // value={`${new BigNumber(
                    //   web3.utils.fromWei(xbfRewards.period1.userSushiReward),
                    // ).toFixed(3)} XBF`}
                    value={`${formatters.toFormatViaBN(
                      web3.utils.fromWei(xbfRewards.period1.userSushiReward),
                      4,
                    )} XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
              <div className={cx(styles.periodBlocks, styles.periodNoBorder)}>
                <div className={cx(styles.periodText, styles.periodTextBlock)}>
                  Percentage (%) of Total Rewards Earned
                </div>
                <div className={styles.periodBlock1}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={XBE}
                    text="XBE Staking & Locking"
                    value={`${calculatePercentage(
                      xbfRewards.period1.stakingReward,
                      xbfRewards.period1.userStakingReward,
                    )}%`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                  />
                </div>
                <div className={styles.periodBlock2}>
                  <XBFBlock
                    className={styles.xbfsmallgridgap}
                    icon={SUSHI}
                    text="SushiSwap LP"
                    value={`${calculatePercentage(
                      xbfRewards.period1.sushiReward,
                      xbfRewards.period1.userSushiReward,
                    )}% XBF`}
                    textClassName={styles.xbfsmallText}
                    valueClassName={styles.xbfmidValue}
                    iconClassName={styles.xbfsmallIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </RequirementWalletProvider>
        <div className={styles.paragraphs}>
          <div className={styles.paragraph}>
            <Text className={styles.paragraphTitle}>Please Note</Text>
            <Text className={styles.paragraphText}>
              These XBF Reward figures are indicative, and may still vary
              slightly as reporting metrics from block snapshots are updated.
            </Text>
          </div>
          <Text className={styles.readMoreText}>READ MORE HERE</Text>
          <Text className={styles.xbfrewardstext}>
            Unpacking XBF
            <a
              className={styles.textLink}
              href="https://xbe.finance/blog/unpacking-xbf/"
              target="_blank"
              // className={styles.guideLink}
              rel="noopener noreferrer"
            >
              READ MORE HERE
            </a>
          </Text>
          <Text className={styles.xbfrewardstext}>
            XBF Rewards
            <a
              className={styles.textLink}
              href="https://xbe.finance/blog/double-reward-week-has-started/"
              target="_blank"
              // className={styles.guideLink}
              rel="noopener noreferrer"
            >
              READ MORE HERE
            </a>
          </Text>
        </div>
      </div>
      <div id="myModal" className={styles.modal} ref={dialog}>
        <div className={styles.modalContent} ref={dialogContent}>
          <Text size="big" className={styles.dialogTitle}>
            Acknowledgement of Terms & Conditions of access
          </Text>
          <Text size="big" align="center" className={styles.dialogContent}>
            {`Please sign the below message (this is a once off acknowledgement
               required to use any of the XBE protocol functions) before proceeding.
                By signing this message you are acknowledging the following: `}
          </Text>
          <Text size="big" align="center" className={styles.dialogContent}>
            {`Use of the xbe.finance website, services, dapp, or application is
              subject to the associated`}
            <a
              href="https://xbe.finance/terms-and-conditions/"
              target="_blank"
              className={styles.guideLink}
              rel="noopener noreferrer"
            >
              {' '}
              terms and conditions
            </a>{' '}
            {`and I hereby confirm
              that I am aware of these and accept them in full.
              Over and above the referred to terms and conditions, I specifically
               confirm and accept the following:  `}
          </Text>
          <ul className={styles.modalList}>
            <li>
              xbe.finance is a smart contract protocol in alpha stage of launch,
              and even though multiple security{' '}
              <a
                href="https://xbe.finance/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.guideLink}
              >
                {' '}
                audits
              </a>{' '}
              have been completed on the smart contracts, I understand the risks
              associated with using the xbe.finance protocol and associated
              functions.
            </li>
            <li>
              Any interactions that I have with the associated XBE protocol
              apps, smart contracts or any related functions MAY place my funds
              at risk, and I hereby release the XBE protocol and its
              contributors, team members, and service providers from any and all
              liability associated with my use of the abovementioned functions.
            </li>
            <li>
              I am lawfully permitted to access this site and use the
              xbe.finance application functions, and I am not in contravention
              of any laws governing my jurisdiction of residence or citizenship.
            </li>
          </ul>
          <Button onClick={handleClick} className={styles.gotItBtn}>
            Sign and Proceed
          </Button>
        </div>
      </div>
    </>
  );
}

export default RewardsBlocks;
