/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { StakeCurveLpActionTypes } from 'store/stakeCurveLp/actionTypes';
import {
  getContract, setNotification, web3,
} from '@workstream/shared';
import {
  stakeCurveLpGetAllowanceAction,
  stakeCurveLpSetAllowanceAction,
} from 'store/stakeCurveLp/actions';
import { selectAddress } from 'store/utils';
import {
  stakeCurveLpTokensAbi, stakeCurveLpTokensAddress, stakeCurveLpVaultTokensAddress,
} from 'store/stakeCurveLp/constants';

function* stakeCurveLpGetAllowanceSaga(
  { payload: token }: ReturnType<typeof stakeCurveLpGetAllowanceAction>,
) {
  try {
    const address: string = yield selectAddress();
    const contract = getContract(
      stakeCurveLpTokensAbi[token],
      stakeCurveLpTokensAddress[token],
    );

    const allowance: string = yield contract
      .methods
      .allowance(address, stakeCurveLpVaultTokensAddress[token])
      .call();

    yield put(stakeCurveLpSetAllowanceAction({
      token,
      allowance: web3.utils.fromWei(allowance),
    }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getAllowanceSaga');
  }
}

export function* getAllowanceSaga() {
  yield takeEvery(
    StakeCurveLpActionTypes.GET_ALLOWANCE,
    stakeCurveLpGetAllowanceSaga,
  );
}
