/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { RequestStatus, setNotification } from '@workstream/shared';
import { rateSetStatusAction, rateGetRate, rateSetRateAction } from 'store/rate/actions';
import { RateActionTypes } from 'store/rate/actionTypes';
import { api } from 'utils';
import { AxiosResponse } from 'axios';
import { RatesTokenBody } from 'store/rate/types';

function* rateGetRateSaga({
  type,
  payload: {
    token,
    currency,
    venue,
  },
}: ReturnType<typeof rateGetRate>) {
  try {
    yield put(rateSetStatusAction({ type, status: RequestStatus.REQUEST }));

    // const { data: payload }: AxiosResponse<RatesTokenBody> =
    // yield api.get(`/rates/get-rates?token=${token}&network=
    // mainnet & currency=${ currency }& venue=${ venue }`);

    // yield put(rateSetRateAction({ token, currency, payload }));
    yield put(rateSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getRate');
    yield put(rateSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getRateSaga() {
  yield takeEvery(RateActionTypes.GET_RATE, rateGetRateSaga);
}
