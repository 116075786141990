import { RequestStatus } from '@workstream/shared';
import { EurXbStakingActionTypes } from 'store/eurXbStaking/actionTypes';
import { EurXbStakingState } from 'store/eurXbStaking/types';

export const eurXbStakingSetStateAction = (payload: Partial<EurXbStakingState>) => ({
  type: EurXbStakingActionTypes.SET_STATE,
  payload,
});

export const eurXbStakingSetStatusAction = (
  payload: { type: EurXbStakingActionTypes, status: RequestStatus },
) => ({
  type: EurXbStakingActionTypes.SET_STATUS,
  payload,
});

export const eurXbStakingGetBlockInfoAction = () => ({
  type: EurXbStakingActionTypes.GET_BLOCK_INFO,
});
