import axios from 'axios';
import { XBF_API_URL } from 'appConstants';

const xbfApi = axios.create({
  baseURL: XBF_API_URL,
});

// @ts-ignore
xbfApi.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';

export default xbfApi;
