import React, {
  ChangeEvent, FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  meSelector, provideLiquiditySelector, useShallowSelector, web3Selector,
} from 'store/selectors';
import { BlockWithList, LiquidityInput } from 'components';
import { DECIMALS } from 'appConstants';
import { BlockWithListProps } from 'components/BlockWithList';
import { RequirementWalletProvider } from 'containers';
import {
  provideLiquidityAddLiquidityAction,
  provideLiquidityAddLiquidityOnlyEthAction,
  provideLiquidityApproveTokenAction,
  provideLiquidityGetPriceAction,
  provideLiquidityGetTokenAllowanceAction,
  provideLiquidityGetTotalLiquidityAction,
} from 'store/provideLiquidity/actions';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  Button,
  CoinEth,
  CoinXbe,
  formatters,
  MetamaskStatus,
  PlatformHeaderBlock,
  Preloader,
  RequestStatus,
  Text,
} from '@workstream/shared';
import BigNumber from 'bignumber.js';
import { fixDecimals } from 'utils';
import styles from './styles.module.scss';

const COINS = [{ name: 'text', img: CoinEth }, { name: 'XBE', img: CoinXbe }];

type Props = {
  className?: string,
};

type Values = {
  amountEth: string,
  amountToken: string,
  isCheckedMaxEth: boolean,
  isCheckedMaxToken: boolean,
};

const initialValues: Values = {
  amountEth: '0',
  amountToken: '0',
  isCheckedMaxEth: false,
  isCheckedMaxToken: false,
};

function checkNeedApprove(allowance: string, value: string): boolean {
  const allowanceBN = new BigNumber(allowance);
  const valueBN = new BigNumber(value);

  if (!valueBN.isGreaterThan(0)) {
    return true;
  }

  return allowanceBN.isLessThan(valueBN);
}

Yup.addMethod(Yup.string, 'amountEth', function (errorMessage: string, ethBalance: string) {
  return this.test('test-amount-eth', errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.decimalPlaces() <= DECIMALS,
        valueBN.isLessThanOrEqualTo(ethBalance),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

Yup.addMethod(Yup.string, 'amountToken', function (
  errorMessage: string,
  tokenBalance: string,
  allowanceToken: string,
) {
  return this.test('test-amount-token', errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.decimalPlaces() <= DECIMALS,
        valueBN.isLessThanOrEqualTo(tokenBalance),
        valueBN.isLessThanOrEqualTo(allowanceToken),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

const ProvideLiquiditySushi: FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const {
    price,
    priceReverse,
    allowanceToken,
  } = useShallowSelector(provideLiquiditySelector.getState);

  const allowanceReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.GET_ALLOWANCE_TOKEN),
  );
  const approveReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.APPROVE_TOKEN),
  );
  const depositReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.ADD_LIQUIDITY),
  );
  const depositOnlyEthReqStatus = useShallowSelector(
    provideLiquiditySelector.getStatus(ProvideLiquidityActionTypes.ADD_LIQUIDITY_ONLY_ETH),
  );
  const ethBalance = useShallowSelector(meSelector.getProp('eth'));
  const { xbe: tokenBalance } = useShallowSelector(meSelector.getProp('balances'));
  const metamaskStatus = useShallowSelector(web3Selector.getProp('metamaskStatus'));

  const ethBalanceFixed = useMemo(() => new BigNumber(ethBalance).toFixed(4), [ethBalance]);
  const tokenBalanceFixed = useMemo(() => new BigNumber(tokenBalance).toFixed(4), [tokenBalance]);

  useEffect(() => {
    dispatch(provideLiquidityGetPriceAction());
    dispatch(provideLiquidityGetTotalLiquidityAction());
    dispatch(provideLiquidityGetTokenAllowanceAction());
  }, [dispatch]);

  const {
    values,
    errors,
    touched,
    isValid,
    resetForm,
    setTouched,
    setValues,
    handleSubmit,
    setErrors,
  } = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      amountEth: Yup.string()
        // @ts-ignore
        .amountEth('Custom val fail', ethBalance)
        .required(),
      amountToken: Yup.string()
        // @ts-ignore
        .amountToken('Custom token val fail', tokenBalance, allowanceToken)
        .required(),
    }),
    onSubmit: ({ amountEth, amountToken }) => {
      dispatch(provideLiquidityAddLiquidityAction({
        amountToken,
        amountEth,
      }));
    },
  });

  /**
   * Reset formik errors after success approve
   */
  useEffect(() => {
    if (allowanceReqStatus === RequestStatus.SUCCESS) {
      setErrors({});
    }
  }, [allowanceReqStatus, setErrors]);

  const optionsList = useMemo<BlockWithListProps['list']>(() => [
    {
      label: 'Price',
      value: formatters.toFormatViaBN(price, 5),
    },
  ], [price]);

  const handleSetTouchedInputs = useCallback(() => {
    setTouched({
      ...touched,
      amountEth: true,
      amountToken: true,
    });
  }, [setTouched, touched]);

  const handleSelectMaxETH = useCallback((checked: boolean) => {
    if (checked) {
      const amountToken = new BigNumber(ethBalance)
        .div(price);

      setValues({
        ...values,
        amountEth: ethBalance,
        amountToken: fixDecimals(amountToken),
        isCheckedMaxEth: checked,
        isCheckedMaxToken: false,
      }).then(() => handleSetTouchedInputs());
    } else {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, touched, ethBalance, tokenBalance, price]);

  const handleSelectMaxToken = useCallback((checked: boolean) => {
    if (checked) {
      const amountEth = new BigNumber(tokenBalance)
        .div(priceReverse);

      setValues({
        ...values,
        amountEth: fixDecimals(amountEth),
        amountToken: tokenBalance,
        isCheckedMaxToken: checked,
        isCheckedMaxEth: false,
      }).then(() => handleSetTouchedInputs());
    } else {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, touched, ethBalance, tokenBalance, priceReverse]);

  const handleChangeETH = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueBN = new BigNumber(value);

    if (valueBN.decimalPlaces() > DECIMALS) return;

    const amountTokenBN = valueBN.multipliedBy(priceReverse);

    setValues({
      ...values,
      amountEth: value,
      amountToken: fixDecimals(amountTokenBN),
      isCheckedMaxEth: false,
      isCheckedMaxToken: false,
    }).then(() => handleSetTouchedInputs());
  }, [handleSetTouchedInputs, setValues, values, priceReverse]);

  const handleChangeToken = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueBN = new BigNumber(value);

    if (valueBN.decimalPlaces() > DECIMALS) return;

    const amountEthBN = valueBN.div(priceReverse);

    setValues({
      ...values,
      amountEth: fixDecimals(amountEthBN),
      amountToken: value,
      isCheckedMaxToken: false,
      isCheckedMaxEth: false,
    }).then(() => handleSetTouchedInputs());
  }, [handleSetTouchedInputs, priceReverse, setValues, values]);

  const isNeedApproveToken = useMemo(
    () => checkNeedApprove(allowanceToken, values.amountToken),
    [allowanceToken, values.amountToken],
  );

  const handleApproveToken = useCallback(() => {
    dispatch(provideLiquidityApproveTokenAction(values.amountToken));
  }, [dispatch, values.amountToken]);

  const isDepositButtonDisabled = useMemo(() => [
    !isValid,
    depositOnlyEthReqStatus === RequestStatus.REQUEST,
    depositReqStatus === RequestStatus.REQUEST,
  ].includes(true), [isValid, depositOnlyEthReqStatus, depositReqStatus]);

  // Only Eth
  const [isCheckedMaxOnlyEth, setIsCheckedMaxOnlyEth] = useState(false);
  const [valueOnlyEth, setValueOnlyEth] = useState('0');

  const handleInputOnlyEth = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueBN = new BigNumber(value);

    if (valueBN.decimalPlaces() > DECIMALS) {
      setValueOnlyEth(valueBN.toFixed(DECIMALS, 1));
    } else {
      setValueOnlyEth(value);
    }
    setIsCheckedMaxOnlyEth(false);
  };

  useEffect(() => {
    if (isCheckedMaxOnlyEth) {
      setValueOnlyEth(new BigNumber(ethBalance).toFixed(DECIMALS, 1));
    }
  }, [isCheckedMaxOnlyEth, ethBalance]);

  const onFundOnlyEthClick = useCallback(() => {
    dispatch(provideLiquidityAddLiquidityOnlyEthAction(
      new BigNumber(valueOnlyEth).toFixed(DECIMALS, 1),
    ));
  }, [dispatch, valueOnlyEth]);

  const isErrorInputOnlyEth = useMemo(() => {
    const valueBN = new BigNumber(valueOnlyEth);
    return [
      valueBN.isGreaterThan(ethBalance),
      valueBN.decimalPlaces() > DECIMALS,
    ].includes(true);
  }, [valueOnlyEth, ethBalance]);

  const isFundOnlyEthButtonDisabled = useMemo(() => [
    valueOnlyEth === '' || valueOnlyEth === '0',
    isErrorInputOnlyEth,
    depositOnlyEthReqStatus === RequestStatus.REQUEST,
    depositReqStatus === RequestStatus.REQUEST,
  ].includes(true),
  [isErrorInputOnlyEth, depositOnlyEthReqStatus, depositReqStatus, valueOnlyEth]);

  return (
    <Preloader isLoading={false} className={className}>
      <RequirementWalletProvider className={cn(styles.container, className)}>
        <div className={cn(
          styles.container,
          className,
          metamaskStatus !== MetamaskStatus.ADDRESS_SELECTED && styles.requirementProvider,
        )}
        >
          <PlatformHeaderBlock
            backgroundColor="grey"
            icon="sushiswap"
            leftText="PLATFORM"
            rightText="SUSHISWAP"
            classRightText={styles.headerRightText}
            sizeText="small"
          />
          <BlockWithList
            list={optionsList}
            theme="whiteShadow"
            className={styles.bottomBlock}
            icons={COINS}
            title="XBE_ETH"
            classNameHeader={styles.header}
            classNameList={styles.options}
            classNameLabel={styles.labelList}
          >
            <form onSubmit={handleSubmit}>
              <LiquidityInput
                value={valueOnlyEth}
                avail={ethBalanceFixed}
                error={isErrorInputOnlyEth}
                icon={CoinEth}
                name="amountOnlyEth"
                className={styles.inputOnlyEth}
                checked={isCheckedMaxOnlyEth}
                onChangeCheckbox={(e) => setIsCheckedMaxOnlyEth(e.target.checked)}
                onChangeInput={handleInputOnlyEth}
              />
              <div className={styles.singleEthWrapper}>
                <div className={styles.singleEthTextWrapper}>
                  <Text
                    className={styles.singleEthText}
                    color="extra"
                  >
                    Fund only in ETH
                  </Text>
                  <Text
                    className={styles.singleEthFountText}
                    size="tinier"
                    color="secondary"
                  >
                    Fund This Pool Using Our Router Contract In Only ETH
                  </Text>
                </div>
                <Button
                  size="tiny"
                  className={styles.singleEthButton}
                  onClick={onFundOnlyEthClick}
                  disabled={isFundOnlyEthButtonDisabled}
                >
                  { depositOnlyEthReqStatus === RequestStatus.REQUEST ? 'Deposit...' : 'Deposit and Stake' }
                </Button>
              </div>
              <div className={styles.inputsList}>
                <LiquidityInput
                  value={values.amountEth}
                  avail={ethBalanceFixed}
                  error={Boolean(errors.amountEth) && Boolean(touched.amountEth)}
                  icon={CoinEth}
                  name="amountEth"
                  className={styles.input}
                  checked={values.isCheckedMaxEth}
                  onChangeCheckbox={(e) => handleSelectMaxETH(e.target.checked)}
                  onChangeInput={handleChangeETH}
                />
                <LiquidityInput
                  value={values.amountToken}
                  avail={tokenBalanceFixed}
                  error={Boolean(errors.amountToken) && Boolean(touched.amountToken)}
                  icon={CoinXbe}
                  name="amountToken"
                  checked={values.isCheckedMaxToken}
                  onChangeInput={handleChangeToken}
                  buttonTextToken="XBE"
                  className={styles.input}
                  buttonDisabledText={approveReqStatus === RequestStatus.REQUEST ? 'Approving...' : 'Approve XBE'}
                  buttonDisabled={
                    values.amountToken.length === 0 ||
                    isValid ||
                    approveReqStatus === RequestStatus.REQUEST
                  }
                  onChangeCheckbox={(e) => handleSelectMaxToken(e.target.checked)}
                  onClickButton={isNeedApproveToken ? handleApproveToken : undefined}
                />
              </div>
              <Button
                type="submit"
                color="primary-dark"
                isOutline
                className={styles.submitBtn}
                size="sm"
                disabled={isDepositButtonDisabled}
              >
                {depositReqStatus === RequestStatus.REQUEST ? 'Processing...' : 'Deposit'}
              </Button>
            </form>
          </BlockWithList>
        </div>
      </RequirementWalletProvider>
    </Preloader>
  );
};

export default ProvideLiquiditySushi;
