import axios from 'axios';
import { API_BASE_URL_OLD } from 'appConstants';

const apiOld = axios.create({
  baseURL: API_BASE_URL_OLD,
});

// @ts-ignore
apiOld.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';

export default apiOld;
