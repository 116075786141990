import { shallowEqual, useSelector } from 'react-redux';
import { State } from 'store/types';

export { default as web3Selector } from './web3';
export { default as provideLiquiditySelector } from './provideLiquidity';
export { default as meSelector } from './me';
export { default as sushiSwapLPStakingSelector } from './sushiSwapLPStaking';
export { default as lockupInfoSelector } from './lockupInfo';
export { default as minterSelector } from './minter';
export { default as boostSelector } from './boost';
export { default as xbeLockupSelector } from './xbeLockup';
export { default as xbeStakingSelector } from './xbeStaking';
export { default as rateSelector } from './rate';
export { default as claimSelector } from './claim';
export { default as xbeClaimSelector } from './xbeClaim';
export { default as referralsMeSelector } from './referralsMe';
export { default as referralsInfoSelector } from './referralsInfo';
export { default as cvxStakingSelector } from './cvxStaking';
export { default as cvxCrvStakingSelector } from './cvxCrvStaking';
export { default as crvConvertingSelector } from './crvConverting';
export { default as statisticsSelector } from './statistics';
export { default as ironBankStakingSelector } from './ironBankStaking';
export { default as eursStakingSelector } from './eursStaking';
export { default as eurXbStakingSelector } from './eurXbStaking';
export { default as stEthStakingSelector } from './stEthStaking';
export { default as usdnStakingSelector } from './usdnStaking';
export { default as stakeCurveLpSelector } from './stakeCurveLp';
export { default as locationSelector } from './location';
export { default as eurtStakingSelector } from './eurtStaking';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useShallowSelector = <T extends (state: State) => any>(selector: T):
ReturnType<T> => useSelector(selector, shallowEqual);
