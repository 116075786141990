/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Text, H1 } from '@workstream/shared';
import cx from 'classnames';
import MAP from 'assets/img/MAP.svg';
import styles from './styles.module.scss';

function Restricted() {
  return (
    <div className={styles.headerContainer}>
      <img src={MAP} alt="map" className={styles.map} />
      <div className={cx(styles.container)}>
        <H1 className={styles.title}>
          SORRY, YOU’RE NOT ABLE TO ACCESS THIS PAGE
        </H1>
        <Text className={styles.description} color="secondary">
          {`You are connecting from a restricted
             country. Pursuant to our terms and conditions
             which can be found `}
          <a
            href="https://xbe.finance/terms-and-conditions/"
            className={styles.guideLink}
          >
            here
          </a>
          ,
          {` we are unable to offer
             you any of our services`}
        </Text>
      </div>
    </div>
  );
}

export default Restricted;
