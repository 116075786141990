/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { ProvideLiquidityActionTypes } from 'store/provideLiquidity/actionTypes';
import {
  getContract,
  RequestStatus,
  setNotification,
  web3,
} from '@workstream/shared';
import {
  provideLiquidityGetTokenAllowanceAction,
  provideLiquiditySetStateAction,
  provideLiquiditySetStatusAction,
} from 'store/provideLiquidity/actions';
import { xbeAbi } from 'assets/abi';
import {
  SUSHISWAP_ROUTER_CONTRACT_ADDRESS,
  XBE_CONTRACT_ADDRESS,
} from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* provideLiquidityGetTokenAllowanceSaga(
  { type }: ReturnType<typeof provideLiquidityGetTokenAllowanceAction>,
) {
  try {
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const tokenContract = getContract(xbeAbi, XBE_CONTRACT_ADDRESS);
    const allowanceToken: string = yield tokenContract
      .methods
      .allowance(address, SUSHISWAP_ROUTER_CONTRACT_ADDRESS)
      .call();

    yield put(provideLiquiditySetStateAction({
      allowanceToken: web3.utils.fromWei(allowanceToken),
    }));
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location providerLiquidityGetTokenAllowanceSaga');
    yield put(provideLiquiditySetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export default function* listener() {
  yield takeEvery(
    ProvideLiquidityActionTypes.GET_ALLOWANCE_TOKEN,
    provideLiquidityGetTokenAllowanceSaga,
  );
}
