import createReducer from 'store/createReducer';
import { UsdnStakingState } from 'store/usdnStaking/types';
import { USDN_STAKING_HANDLERS } from 'store/usdnStaking/handlers';
import { RequestStatus } from '@workstream/shared';
import { UsdnStakingActionTypes } from 'store/usdnStaking/actionTypes';

const initialState: UsdnStakingState = {
  earned: '0',
  apr: '0',
  deposits: '0',
  tvl: '0',
  ui: {
    [UsdnStakingActionTypes.GET_BLOCK_INFO]: RequestStatus.INIT,
  },
};

export default createReducer(initialState, USDN_STAKING_HANDLERS);
