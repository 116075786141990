/* eslint-disable no-console */
import {
  put, takeEvery, all,
} from 'redux-saga/effects';
import BigNumber from 'bignumber.js';
import { AxiosResponse } from 'axios';
import { api } from 'utils';
import { CrvConvertingActionTypes } from 'store/crvConverting/actionTypes';
import { ResponsePoolInfo } from 'store/types';
import {
  crvConvertingGetAllowanceAction,
  crvConvertingGetBlockInfoAction,
  crvConvertingSetStateAction,
  crvConvertingSetStatusAction,
} from 'store/crvConverting/actions';
import {
  RequestStatus, setNotification, getContract,
} from '@workstream/shared';
import { cvxCrvVaultAbi, wethAbi } from 'assets/abi';
import { CVX_CRV_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { selectAddress } from 'store/utils';

function* crvConvertingGetBlockInfoSaga(
  { type }: ReturnType<typeof crvConvertingGetBlockInfoAction>,
) {
  try {
    yield put(crvConvertingSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const contract = getContract(cvxCrvVaultAbi, CVX_CRV_VAULT_CONTRACT_ADDRESS);

    const tokensCount: string = yield contract
      .methods
      .getRewardTokensCount()
      .call();

    const listRewardsContract: string[] = yield all(
      Array(Number(tokensCount)).fill(0).map((_, index) => contract
        .methods
        .getRewardToken(index)
        .call()),
    );

    const listRewardsContractSymbol: string[] = yield all(
      listRewardsContract.map((addressReward) => {
        const contractReward = getContract(wethAbi, addressReward);
        return contractReward
          .methods
          .symbol()
          .call();
      }),
    );

    const { data }: AxiosResponse<ResponsePoolInfo> = yield api.get(`/tokens/get-pool-info?address=${address}&poolName=crvVault`);

    yield put(crvConvertingSetStateAction({
      earned: new BigNumber(data[0].earned).toFixed(2),
      deposits: new BigNumber(data[0].deposits).toFixed(2),
      tvl: new BigNumber(data[0].tvl).toFixed(2),
      apr: new BigNumber(data[0].apr).toFixed(2),
      listRewardsContract: listRewardsContract.map((addressReward, index) => ({
        address: addressReward,
        symbol: listRewardsContractSymbol[index],
      })),
    }));
    yield put(crvConvertingGetAllowanceAction());
    yield put(crvConvertingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    console.log('error', e.message);
    console.log('error location getBlockInfoSaga');
    yield put(crvConvertingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getBlockInfoSaga() {
  yield takeEvery(
    CrvConvertingActionTypes.GET_BLOCK_INFO,
    crvConvertingGetBlockInfoSaga,
  );
}
