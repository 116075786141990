/* eslint-disable no-console */
import {
  referralsInfoGetStatisticsAction,
  referralsInfoSetStateAction,
  referralsInfoSetStatusAction,
} from 'store/referrals/info/actions';
import { put, takeEvery } from 'redux-saga/effects';
import { ReferralsInfoActionTypes } from 'store/referrals/info/actionTypes';
import { RequestStatus, setNotification } from '@workstream/shared';
import { selectAddress } from 'store/utils';
import { AxiosResponse } from 'axios';
import { api } from 'utils';

type Result = {
  members: {
    totalAmount: number,
    list: string[],
  },
  deposits: {
    totalSum: number,
    list: string[],
  },
  claimable: number,
  referralHits: number,
};

function* referralsInfoGetStatisticsSaga(
  { type }: ReturnType<typeof referralsInfoGetStatisticsAction>,
) {
  try {
    yield put(referralsInfoSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();

    const result: AxiosResponse<Result> = yield api.get(`/users/user/referral-statistics?address=${address}`);

    yield put(referralsInfoSetStateAction({
      members: {
        totalAmount: result.data.members.totalAmount.toString(),
        tier1: result.data.members.list[0].toString(),
        tier2: result.data.members.list[1].toString(),
        tier3: result.data.members.list[2].toString(),
      },
      networkDeposit: {
        totalAmount: result.data.deposits.totalSum.toString(),
        tier1: result.data.deposits.list[0].toString(),
        tier2: result.data.deposits.list[1].toString(),
        tier3: result.data.deposits.list[2].toString(),
      },
      claimableRewards: result.data.claimable.toFixed(2),
      referralHits: result.data.referralHits.toString(),
    }));
    yield put(referralsInfoSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    if (!e.response || (e.response && e.response.status !== 404)) {
      setNotification({ type: 'error', message: e.message });
      console.log('error', e.message);
      console.log('error location getStatisticsSaga');
    }
    yield put(referralsInfoSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* getStatisticsSaga() {
  yield takeEvery(
    ReferralsInfoActionTypes.GET_STATISTICS,
    referralsInfoGetStatisticsSaga,
  );
}
