import {
  cvxCrvStakingWithdrawAction,
  cvxCrvStakingGetBlockInfoAction,
  cvxCrvStakingSetStatusAction,
} from 'store/cvxCrvStaking/actions';
import { put, takeLeading } from 'redux-saga/effects';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import {
  getContract, RequestStatus, setNotification, web3,
} from '@workstream/shared';
import { cvxCrvVaultAbi } from 'assets/abi';
import { CVX_CRV_VAULT_CONTRACT_ADDRESS } from 'appConstants/contracts';
import { meGetBalancesAction } from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import { selectAddress } from 'store/utils';

function* cvxCrvStakingWithdrawSaga(
  { type, payload }: ReturnType<typeof cvxCrvStakingWithdrawAction>,
) {
  try {
    yield put(cvxCrvStakingSetStatusAction({ type, status: RequestStatus.REQUEST }));
    const address: string = yield selectAddress();
    const contract = getContract(cvxCrvVaultAbi, CVX_CRV_VAULT_CONTRACT_ADDRESS);

    yield contract
      .methods
      .withdraw(web3.utils.toWei(payload))
      .send({
        from: address,
      });

    yield put(cvxCrvStakingGetBlockInfoAction());
    yield put(meGetBalancesAction(BalancesToken.CVX_CRV));
    setNotification({ type: 'success', message: 'Withdraw success!' });
    yield put(cvxCrvStakingSetStatusAction({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    setNotification({ type: 'error', message: e.message });
    yield put(cvxCrvStakingSetStatusAction({ type, status: RequestStatus.ERROR }));
  }
}

export function* withdrawSaga() {
  yield takeLeading(
    CvxCrvStakingActionTypes.WITHDRAW,
    cvxCrvStakingWithdrawSaga,
  );
}
