import { RequestStatus } from '@workstream/shared';
import { CvxCrvStakingActionTypes } from 'store/cvxCrvStaking/actionTypes';
import { CvxCrvStakingState } from 'store/cvxCrvStaking/types';

export const cvxCrvStakingSetStateAction = (payload: Partial<CvxCrvStakingState>) => ({
  type: CvxCrvStakingActionTypes.SET_STATE,
  payload,
});

export const cvxCrvStakingSetStatusAction = (
  payload: { type: CvxCrvStakingActionTypes, status: RequestStatus },
) => ({
  type: CvxCrvStakingActionTypes.SET_STATUS,
  payload,
});

export const cvxCrvStakingGetBlockInfoAction = () => ({
  type: CvxCrvStakingActionTypes.GET_BLOCK_INFO,
});

export const cvxCrvStakingGetAllowanceAction = () => ({
  type: CvxCrvStakingActionTypes.GET_ALLOWANCE,
});

export const cvxCrvStakingApproveAction = (payload: string) => ({
  type: CvxCrvStakingActionTypes.APPROVE,
  payload,
});

export const cvxCrvStakingDepositAction = (payload: string) => ({
  type: CvxCrvStakingActionTypes.DEPOSIT,
  payload,
});

export const cvxCrvStakingWithdrawAction = (payload: string) => ({
  type: CvxCrvStakingActionTypes.WITHDRAW,
  payload,
});
