export { default as routes } from './routes';
export * from './api';
export * from './contracts';
export * from './navLinks';
export * from './web3';
export * from './enums';
export * from './poolInfo';
export * from './tokenInfo';

export const minBoost = 0.4;
export const maxBoost = 1;

export const DECIMALS = 18;
