import React, {
  FC, useEffect, useCallback, useMemo,
} from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BigNumber from 'bignumber.js';
import {
  Button, DefaultBlock, Text, web3, WriteContractStatus,
} from '@workstream/shared';
import { RequirementWalletProvider } from 'containers';
import {
  crvConvertingGetBlockInfoAction,
  crvConvertingConvertAndStakeAction,
  crvConvertingApproveAction,
} from 'store/crvConverting/actions';
import {
  meGetBalancesAction,
} from 'store/me/actions';
import { BalancesToken } from 'store/me/constants';
import {
  meSelector, useShallowSelector, crvConvertingSelector,
} from 'store/selectors';
import { InputWithAvailable } from 'components';
import styles from './styles.module.scss';

type Props = {
  className?: string,
};

const DESCRIPTION = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.';

const NOTE = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore elit, sed do eiusmod.';

const initialValues = {
  deposit: '',
};

Yup.addMethod(Yup.string, 'deposit', function (
  errorMessage: string,
  maxDeposit: string,
  allowance: string,
) {
  return this.test('test-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      const valueBN = new BigNumber(value);

      const conditions: boolean[] = [
        valueBN.isGreaterThan(0),
        valueBN.isLessThanOrEqualTo(maxDeposit),
        valueBN.isLessThanOrEqualTo(web3.utils.fromWei(allowance)),
      ];

      if (conditions.includes(false)) {
        return createError({
          path,
          message: errorMessage,
        });
      }
      return true;
    }

    return false;
  });
});

const ConvertStake: FC<Props> = ({
  className,
}) => {
  const dispatch = useDispatch();

  const {
    balances: {
      crv: crvBalance,
    },
  } = useShallowSelector(meSelector.getState);
  const { allowance, stakingStatus } = useShallowSelector(crvConvertingSelector.getState);

  const {
    values,
    setValues,
    isValid,
    handleSubmit,
    validateField,
  } = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      deposit: Yup.string()
      // @ts-ignore
        .deposit('Error', crvBalance, allowance)
        .required(),
    }),
    onSubmit: ({ deposit }) => {
      dispatch(crvConvertingConvertAndStakeAction(deposit));
    },
  });

  useEffect(() => {
    dispatch(crvConvertingGetBlockInfoAction());
    dispatch(meGetBalancesAction(BalancesToken.CRV));
  }, [dispatch]);

  useEffect(() => {
    validateField('deposit');
  }, [allowance, validateField]);

  const onApproveClick = () => {
    if (!values.deposit || values.deposit === '0') {
      dispatch(crvConvertingApproveAction('0'));
    } else {
      dispatch(crvConvertingApproveAction(values.deposit));
    }
  };

  const handleMaxValue = useCallback(() => {
    setValues({ deposit: crvBalance });
  }, [crvBalance, setValues]);

  const handleChangeDeposit = useCallback((event) => {
    setValues({ deposit: event.target.value });
  }, [setValues]);

  const isDisabledStake = useMemo(() => [
    !isValid,
    stakingStatus === WriteContractStatus.APPROVING,
    stakingStatus === WriteContractStatus.WRITING,
  ].includes(true), [isValid, stakingStatus]);

  const isDisabledApprove = useMemo(() => stakingStatus === WriteContractStatus.APPROVING ||
    stakingStatus === WriteContractStatus.WRITING, [stakingStatus]);

  return (
    <RequirementWalletProvider>
      <form
        className={cx(styles.container, className)}
        onSubmit={handleSubmit}
      >
        <div className={styles.left}>
          <Text
            className={styles.description}
            size="small"
            color="secondary"
          >
            {DESCRIPTION}
          </Text>

          <DefaultBlock
            className={styles.noteWrapper}
            theme="lightBlue"
            tag="div"
          >
            <Text
              className={styles.note}
              color="secondary"
              size="small"
            >
              <b>Note: </b>{NOTE}
            </Text>
          </DefaultBlock>
        </div>
        <div className={styles.right}>
          <Text
            className={styles.amountText}
            color="secondary"
            size="small"
          >
            Amount of CRV tokens you wish to convert to cvxCRV and stake:
          </Text>
          <InputWithAvailable
            className={styles.inputWithAvailable}
            name="deposit"
            placeholder="0.00"
            available={`${crvBalance} CRV`}
            value={values.deposit}
            onChange={handleChangeDeposit}
            onMaxClick={handleMaxValue}
          />
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              size="sm"
              isOutline
              onClick={onApproveClick}
              disabled={isDisabledApprove}
            >
              { stakingStatus === WriteContractStatus.APPROVING ? 'Approving...' : 'Approve' }
            </Button>
            <Button
              type="submit"
              className={styles.button}
              size="sm"
              isOutline
              disabled={isDisabledStake}
            >
              { stakingStatus === WriteContractStatus.WRITING ? 'Writing...' : 'Convert and Stake'}
            </Button>
          </div>
        </div>
      </form>
    </RequirementWalletProvider>
  );
};

export default ConvertStake;
