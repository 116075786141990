import {
  PlatformSushiswap,
} from 'assets/img';
import {
  sushiswapRouterAbi,
} from 'assets/abi';
import { LiquidityPool, Token, StakingPool } from 'appConstants';
import {
  CoinUSDT, CoinXbe, PropsButton, CoinSetProps, CoinEth,
} from '@workstream/shared';
import { TOKEN_ADDRESSES } from './tokenInfo';

export const POOL_TOKEN_ICONS: Record<LiquidityPool, CoinSetProps['icons']> = {
  ETH_USDT: [{ name: 'eth', img: CoinEth }, { name: 'usdt', img: CoinUSDT }],
  ETH_XBE: [{ name: 'usdt', img: CoinEth }, { name: 'eurxb', img: CoinXbe }],
  XBE_USDT: [{ name: 'usdt', img: CoinXbe }, { name: 'usdt`', img: CoinUSDT }],
};

export const POOL_PLATFORM_LOGO: Record<LiquidityPool, string> = {
  ETH_USDT: PlatformSushiswap,
  ETH_XBE: PlatformSushiswap,
  XBE_USDT: PlatformSushiswap,
};

export const POOL_PLATFORM_NAME: Record<LiquidityPool, StakingPool> = {
  ETH_USDT: StakingPool.sushiswap,
  ETH_XBE: StakingPool.sushiswap,
  XBE_USDT: StakingPool.sushiswap,
};

export const POOL_TOKENS: Record<LiquidityPool, {
  token1: {
    name: Token,
    address: string,
  },
  token2: {
    name: Readonly<Token>,
    address: Readonly<string>,
  }
}
> = {

  ETH_USDT: {
    token1: {
      name: Token.ETH,
      address: TOKEN_ADDRESSES.eth,
    },
    token2: {
      name: Token.USDT,
      address: TOKEN_ADDRESSES.usdt,
    },
  },
  ETH_XBE: {
    token1: {
      name: Token.ETH,
      address: TOKEN_ADDRESSES.eth,
    },
    token2: {
      name: Token.XBE,
      address: TOKEN_ADDRESSES.xbe,
    },
  },
  XBE_USDT: {
    token1: {
      name: Token.XBE,
      address: TOKEN_ADDRESSES.xbe,
    },
    token2: {
      name: Token.USDT,
      address: TOKEN_ADDRESSES.usdt,
    },
  },
};

export const POOL_COLORS: Record<LiquidityPool, PropsButton['color']> = {
  ETH_USDT: 'blue',
  ETH_XBE: 'primary-dark',
  XBE_USDT: 'blue',
};

export const POOL_NAMES: Record<LiquidityPool, string> = {
  ETH_USDT: 'ETH_USDT',
  ETH_XBE: 'ETH_XBE',
  XBE_USDT: 'XBE_USDT',
};

export const POOL_ADDRESSES: Record<LiquidityPool, string> = {
  ETH_USDT: process.env.REACT_APP_ETH_USDT_POOL as string,
  ETH_XBE: process.env.REACT_APP_XBE_ETH_POOL as string,
  XBE_USDT: process.env.REACT_APP_XBE_USDT_POOL as string,
};

export const POOL_ADDRESSES_ROUTER: Record<LiquidityPool, string> = {
  ETH_USDT: process.env.REACT_APP_UNISWAP_ROUTER_ADDRESS as string,
  ETH_XBE: process.env.REACT_APP_SUSHISWAP_ROUTER_ADDRESS as string,
  XBE_USDT: process.env.REACT_APP_SUSHISWAP_ROUTER_ADDRESS as string,
};

export const STAKING_POOL_COLORS: Record<StakingPool, PropsButton['color']> = {
  uniswap: 'purple',
  sushiswap: 'primary-dark',
};

export const PAIR_ADDRESSES: Record<StakingPool, string> = {
  uniswap: process.env.REACT_APP_UNISWAP_USDT_XBE_PAIR_ADDRESS as string,
  sushiswap: process.env.REACT_APP_SUSHISWAP_USDT_XBE_PAIR_ADDRESS as string,
};

export const PAIR_ABIS: Record<StakingPool, object> = {
  uniswap: sushiswapRouterAbi,
  sushiswap: sushiswapRouterAbi,
};

export const STAKING_POOL_PAIR_ADDRESSES: Record<LiquidityPool, object> = {
  ETH_USDT: sushiswapRouterAbi,
  ETH_XBE: sushiswapRouterAbi,
  XBE_USDT: sushiswapRouterAbi,
};

export const STAKING_CONTRACT_ADDRESSES: Record<StakingPool, string> = {
  uniswap: process.env.REACT_APP_UNISWAP_INCENTIVIZER as string,
  sushiswap: process.env.REACT_APP_SUSHISWAP_INCENTIVIZER as string,
};
