export enum LockupInfoActionTypes {
  SET_STATE = 'LOCKUP_INFO.SET_STATE',

  SET_STATUS = 'LOCKUP_INFO.SET_STATUS',

  GET_BLOCK_INFO = 'LOCKUP_INFO.GET_BLOCK_INFO',

  GET_PRICE_SUCCESS = 'LOCKUP_INFO.GET_PRICE_SUCCESS',

  GET_PRICE = 'LOCKUP_INFO.GET_PRICE',

  SET_REVERSE_PRICE = 'LOCKUP_INFO.SET_REVERSE_PRICE',
}
