import React, { FC, PropsWithChildren } from 'react';
import {
  MetamaskStatus, Preloader, ProviderLoadingStatus, useMetamask,
} from '@workstream/shared';
import { useDispatch } from 'react-redux';
import { web3SetStateAction } from 'store/web3/actions';
import styles from './styles.module.scss';

const Web3Provider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const { loadingStatus } = useMetamask({
    onSetProvider: (providerValues) => dispatch(web3SetStateAction({
      address: providerValues.account,
      metamaskStatus: providerValues.metamaskStatus,
      chainId: providerValues.chainID || '',
    })),
    onChainChange: (chainID) => dispatch(web3SetStateAction({
      chainId: chainID || '',
    })),
    onAccountChange: (account) => dispatch(web3SetStateAction({
      address: account,
      metamaskStatus: account ? MetamaskStatus.ADDRESS_SELECTED : MetamaskStatus.AVAILABLE,
    })),
  });

  return (
    <Preloader
      className={styles.preloader}
      isLoading={loadingStatus !== ProviderLoadingStatus.LOADED}
    >
      {children}
    </Preloader>
  );
};

export default Web3Provider;
