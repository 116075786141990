export enum StakeCurveLpActionTypes {
  SET_STATE = 'STAKE_CURVE_LP.SET_STATE',

  SET_STATUS = 'STAKE_CURVE_LP.SET_STATUS',

  GET_BALANCE = 'STAKE_CURVE_LP.GET_BALANCE',
  SET_BALANCE = 'STAKE_CURVE_LP.SET_BALANCE',

  GET_BALANCE_VAULT = 'STAKE_CURVE_LP.GET_BALANCE_VAULT',
  SET_BALANCE_VAULT = 'STAKE_CURVE_LP.SET_BALANCE_VAULT',

  APPROVE = 'STAKE_CURVE_LP.APPROVE',

  GET_ALLOWANCE = 'STAKE_CURVE_LP.GET_ALLOWANCE',
  SET_ALLOWANCE = 'STAKE_CURVE_LP.SET_ALLOWANCE',

  DEPOSIT = 'STAKE_CURVE_LP.DEPOSIT',

  SET_SUBMIT_STATUS = 'STAKE_CURVE_LP.SET_SUBMIT_STATUS',

  WITHDRAW = 'STAKE_CURVE_LP.WITHDRAW',
  SET_WITHDRAW_STATUS = 'STAKE_CURVE_LP.SET_WITHDRAW_STATUS',

  GET_REWARDS_CONTRACTS_ADDRESSES = 'STAKE_CURVE_LP.GET_REWARDS_CONTRACTS_ADDRESSES',
  SET_REWARDS_CONTRACTS_ADDRESSES = 'STAKE_CURVE_LP.SET_REWARDS_CONTRACTS_ADDRESSES',

  GET_LP_TOKEN_ADDRESS = 'STAKE_CURVE_LP.GET_LP_TOKEN_ADDRESS',
  SET_LP_TOKEN_ADDRESS = 'STAKE_CURVE_LP.SET_LP_TOKEN_ADDRESS',
}
